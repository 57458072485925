import { ENDPOINTS } from "../endpoints";

export interface GenerateMoreParams {
  orderId: string;
}

export const createOrderGenerateMoreV2 = async (
  { orderId }: GenerateMoreParams,
  headers: { Authorization: string }
): Promise<boolean> => {
  const endpoint = `${ENDPOINTS.ORDER}/${orderId}${ENDPOINTS.ORDER_GENERATE_MORE_NEW}`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(
        `Failed to generate more: ${response.status} - ${errorMessage}`
      );
    }

    return response.status === 204;
  } catch (error) {
    console.error("Error generating more:", error);
    throw error;
  }
};
