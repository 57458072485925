import { ENDPOINTS } from "../endpoints";

export interface OrderGenerateMoreParams {
  orderId: string;
}

// Adjusted to accept headers
export const createOrderGenerateMore = async (
  { orderId }: OrderGenerateMoreParams,
  headers: { Authorization: string }
): Promise<boolean> => {
  const endpoint = `${ENDPOINTS.ORDER}/${orderId}${ENDPOINTS.ORDER_GENERATE_MORE}`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to get order generate more");
    }

    const status = response.status;

    return status === 204;
  } catch (error) {
    console.error("Error getting order generate more:", error);
    throw error;
  }
};
