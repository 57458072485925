export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const ENDPOINTS = {
  SPACES: `${API_BASE_URL}/spaces`,
  ROOM_PROPERTIES: `${API_BASE_URL}/spaces/room_properties`,
  ORDER_NEW: `${API_BASE_URL}/order/order_new`,
  ORDER: `${API_BASE_URL}/order`,
  ORDER_STATUS: "/status",
  ORDER_STATUS_NEW: "/status_new",
  ORDER_GENERATE_MORE: `/generate_more`,
  ORDER_GENERATE_MORE_NEW: `/generate_more_new`,
  GALLERY: `${API_BASE_URL}/gallery`,
  SUBSCRIPTION: `${API_BASE_URL}/user/subscription`,
  PAYMENT: `${API_BASE_URL}/payment`,
  CANCEL_SUBSCRIPTION: `${API_BASE_URL}/payment/cancel_subscription`,
  MANAGE_SUBSCRIPTION: `${API_BASE_URL}/payment/manage_subscription`,
  PAYMENT_HISTORY: `${API_BASE_URL}/payment/history`,
  NEWSLETTER: `${API_BASE_URL}/newsletter`,
  IMAGES: `/images`,
  DOWNLOAD: `/download`,
  PAYMENT_CUSTOMER_EMAIL: `${API_BASE_URL}/payment/session_customer_email`,
};
