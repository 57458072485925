import { ENDPOINTS } from "../endpoints";

export interface Space {
  id: string;
  name: string;
  title: string;
  icon_src: string;
  styles: Style[];
}

export interface Style {
  id: string;
  title: string;
  icon_src: string;
  widget_id: string;
}

// TODO CLEAN UP OLD API
export const getSpaces = async (): Promise<Space[]> => {
  try {
    const response = await fetch(ENDPOINTS.SPACES, {
      method: "GET",
      headers: { Accept: "application/json" },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch spaces");
    }

    const data = (await response.json()) as Space[];
    return data;
  } catch (error) {
    console.error("Error fetching spaces:", error);
    throw error;
  }
};
