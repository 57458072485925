import { ENDPOINTS } from "../endpoints";

export type NewOrderParams = {
  image: File; // The image file
  removeFurniture: boolean;
  roomStyle: string;
  roomType: string;
  demoId: string | null;
};

export type NewOrderResponse = {
  order_id: string;
};

// Adjusted to accept headers
export const createOrderV2 = async (
  { image, removeFurniture, roomStyle, roomType, demoId }: NewOrderParams,
  headers: { Authorization: string }
): Promise<NewOrderResponse> => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("remove_furniture", removeFurniture.toString());
  formData.append("room_style", roomStyle);
  formData.append("room_type", roomType);
  if (demoId !== null) {
    formData.append("demo_id", demoId);
  }

  try {
    const response = await fetch(ENDPOINTS.ORDER_NEW, {
      method: "POST",
      headers,
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to create order");
    }

    const data: NewOrderResponse = await response.json();

    return data;
  } catch (error) {
    console.error("Error creating order:", error);
    throw error;
  }
};
