import ReactGA from "react-ga4";

export enum EventCategory {
  Button = "Button",
  Link = "Link",
  Navigation = "Navigation",
  Payment = "Payment",
  Photo = "Photo",
  OrderSettings = "Order settings",
  OrderCreate = "Order create",
  SampleImageGallery = "Sample image gallery",
  FAQ = "FAQ",
  BillingToggle = "Billing toggle",
}

export enum EventAction {
  Click = "Clicked",
  Upload = "Upload",
  Create = "Create",
  Submit = "Submit",
  Switched = "Switched",
  Open = "Opened",
}

interface TrackEventParams {
  category: EventCategory;
  action: EventAction | string;
  label?: string;
  extraParams?: Record<string, string | number>;
}

export const trackEvent = ({
  category,
  action,
  label,
  extraParams,
}: TrackEventParams) => {
  ReactGA.event({
    category,
    action,
    label,
    ...extraParams,
  });
};

export const trackButtonClickEvent = ({
  label,
  extraParams,
}: {
  label?: string;
  extraParams?: Record<string, string | number>;
}) => {
  trackEvent({
    category: EventCategory.Button,
    action: EventAction.Click,
    label,
    extraParams,
  });
};

export const trackLinkClickEvent = ({
  linkName,
  url,
}: {
  linkName: string;
  url: string;
}) => {
  trackEvent({
    category: EventCategory.Link,
    action: EventAction.Click,
    label: linkName,
    extraParams: { url },
  });
};

export const trackNavigationClickEvent = ({
  navItem,
  url,
}: {
  navItem: string;
  url: string;
}) => {
  trackEvent({
    category: EventCategory.Navigation,
    action: EventAction.Click,
    label: navItem,
    extraParams: { url },
  });
};

export const trackPaymentEvent = ({
  event,
  label,
}: {
  event: string;
  label?: string;
}) => {
  trackEvent({
    category: EventCategory.Payment,
    action: event,
    label,
  });
};

export const trackPhotoUploadEvent = ({
  isLoggedInUser,
  event,
  label,
}: {
  isLoggedInUser: boolean;
  event: string;
  label?: string;
}) => {
  trackEvent({
    category: EventCategory.Photo,
    action: event,
    label,
    extraParams: { variant: isLoggedInUser ? "PAID" : "FREE" },
  });
};

export const trackOrderCreationSettingsEvent = ({
  event,
  label,
}: {
  event: string;
  label?: string;
}) => {
  trackEvent({
    category: EventCategory.OrderSettings,
    action: event,
    label,
  });
};
