import React, { useState } from "react";
import { Box } from "@mui/material";

import { PhotoPage } from "./PhotoPage";
import { customColors } from "../../theme";
import { UploadPhoto } from "./UploadPhoto";

export const ProcessPhotoComponent: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<"UPLOAD" | "LOAD" | "RESULT">(
    "UPLOAD"
  );

  const [orderId, setOrderId] = useState<string>("");

  const onPhotoSubmited = (orderId: string) => {
    setOrderId(orderId);
    setCurrentPage("RESULT");
  };

  return (
    <Box
      sx={{
        maxWidth: "1400px",
        margin: "0 auto",
        backgroundColor: customColors.semiDarkGray,
        borderRadius: "12px",
      }}
    >
      {currentPage === "UPLOAD" ? (
        <UploadPhoto onPhotoSubmited={onPhotoSubmited} />
      ) : (
        <PhotoPage orderIdProp={orderId} />
      )}
    </Box>
  );
};
