import { ENDPOINTS } from "../endpoints";

interface OrderParams {
  orderId: string;
}

export type GetOrderStatusResponse = {
  percentage: number;
  time_remaining: number;
  is_processed: boolean;
};

// Adjusted to accept headers
export const getOrderStatusV2 = async (
  { orderId }: OrderParams,
  headers: { Authorization: string }
): Promise<GetOrderStatusResponse> => {
  const endpoint = ENDPOINTS.ORDER + "/" + orderId + ENDPOINTS.ORDER_STATUS_NEW;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error("Failed to get order status");
    }

    const data: GetOrderStatusResponse = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting order status:", error);
    throw error;
  }
};
