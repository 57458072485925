import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";

import Grid from "@mui/material/Grid2";
import ImageIcon from "@mui/icons-material/Image";
import { customColors } from "../theme";
import { useTranslation } from "react-i18next";
import IconTitle from "./IconTitle";
import { Done } from "@mui/icons-material";

export const WhyUs: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 900px)");

  type InfoPoint = {
    title: string;
    description: string;
  };

  const usPoints: InfoPoint[] = [
    {
      title: t("TEXT.WHY_US.US.INSTANT_RESULTS"),
      description: t("TEXT.WHY_US.US.INSTANT_RESULTS_DESCRIPTION"),
    },
    {
      title: t("TEXT.WHY_US.US.ONE_CLICK_STAGING"),
      description: t("TEXT.WHY_US.US.ONE_CLICK_STAGING_DESCRIPTION"),
    },
    {
      title: t("TEXT.WHY_US.US.COST_EFFECTIVE"),
      description: t("TEXT.WHY_US.US.COST_EFFECTIVE_DESCRIPTION"),
    },
    {
      title: t("TEXT.WHY_US.US.UNLIMITED_REGENERATIONS"),
      description: t("TEXT.WHY_US.US.UNLIMITED_REGENERATIONS_DESCRIPTION"),
    },

    {
      title: t("TEXT.WHY_US.US.FULL_COPYRIGHT"),
      description: t("TEXT.WHY_US.US.FULL_COPYRIGHT_DESCRIPTION"),
    },
    {
      title: t("TEXT.WHY_US.US.MLS_COMPLIANT"),
      description: t("TEXT.WHY_US.US.MLS_COMPLIANT_DESCRIPTION"),
    },
  ];

  const othersPoints: InfoPoint[] = [
    {
      title: t("TEXT.WHY_US.OTHERS.MULTIPLE_DAYS"),
      description: t("TEXT.WHY_US.OTHERS.MULTIPLE_DAYS_DESCRIPTION"),
    },
    {
      title: t("TEXT.WHY_US.OTHERS.TEDIOUS_INSTRUCTIONS"),
      description: t("TEXT.WHY_US.OTHERS.TEDIOUS_INSTRUCTIONS_DESCRIPTION"),
    },
    {
      title: t("TEXT.WHY_US.OTHERS.EXPENSIVE"),
      description: t("TEXT.WHY_US.OTHERS.EXPENSIVE_DESCRIPTION"),
    },
    {
      title: t("TEXT.WHY_US.OTHERS.LIMITED_REVISIONS"),
      description: t("TEXT.WHY_US.OTHERS.LIMITED_REVISIONS_DESCRIPTION"),
    },
    {
      title: t("TEXT.WHY_US.OTHERS.HUMAN_ERROR"),
      description: t("TEXT.WHY_US.OTHERS.HUMAN_ERROR_DESCRIPTION"),
    },
    {
      title: t("TEXT.WHY_US.OTHERS.UNCLEAR_COPYRIGHT"),
      description: t("TEXT.WHY_US.OTHERS.UNCLEAR_COPYRIGHT_DESCRIPTION"),
    },
    {
      title: t("TEXT.WHY_US.OTHERS.GO_OVERBOARD"),
      description: t("TEXT.WHY_US.OTHERS.GO_OVERBOARD_DESCRIPTION"),
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: isMobile ? "100%" : "770px",
        backgroundImage:
          'url("https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fe2.jpg?alt=media&token=c2d4b335-7024-4408-847a-e2a34bef18a2")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "80px 0",
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.4)", // Adjust opacity here
          zIndex: 1,
        }}
      />

      {/* Content above overlay */}
      <Box
        sx={{
          position: "relative", // Needed to appear above overlay
          zIndex: 2,
          padding: { xs: "20px 20px", md: "40px 20px" },
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <Grid
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: 0,
            alignItems: "center",
          }}
        >
          <Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <IconTitle
                title={t("TEXT.WHY_US.ICON_TITLE")}
                centered={true}
                icon={
                  <ImageIcon
                    fontSize={"medium"}
                    sx={{ color: customColors.gold }}
                  />
                }
              />
            </div>
            <Typography
              variant={isMobile ? "h6" : "h4"}
              component="div"
              gutterBottom
              sx={{ textAlign: "center" }}
            >
              <b>{t("TEXT.WHY_US.TITLE")}</b> {t("TEXT.WHY_US.TITLE2")}
            </Typography>
          </Box>
        </Grid>

        <Box
          sx={{
            padding: { xs: "0", md: "20px 20px" },
            marginTop: { xs: "40px" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70px",
              height: "70px",
              borderRadius: "50%",
              backgroundColor: "#000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              zIndex: 100,
              fontWeight: "bold",
              fontSize: "26px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)", // Subtle shadow for the VS circle
              transition: "transform 0.3s ease", // Hover animation
              "&:hover": {
                transform: "translate(-50%, -50%) scale(1.05)", // Slight scaling on hover
              },
            }}
          >
            VS
          </Box>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            {/* US */}
            <Grid size={{ xs: 12, md: 5 }}>
              <Box
                sx={{
                  borderRadius: "12px",
                  overflow: "hidden",
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)", // Slight hover effect
                  },
                }}
              >
                {/* Gold header */}
                <Box
                  sx={{
                    backgroundColor: customColors.gold,
                    padding: "20px 30px",
                  }}
                >
                  <Typography
                    variant="h6"
                    letterSpacing={0.2}
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    VIRTUAL STAGING PRO AI
                  </Typography>
                </Box>

                {/* White content */}
                <Box sx={{ padding: "30px" }}>
                  {usPoints.map((point) => (
                    <Box
                      key={point.title}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "12px",
                      }}
                    >
                      <Done
                        fontSize="small"
                        sx={{ color: customColors.gold }}
                      />
                      <Typography
                        fontSize="15px"
                        sx={{ mb: 2, ml: "10px", color: "#000" }}
                      >
                        <b>
                          {point.title}
                          {": "}
                        </b>
                        {point.description}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>

            {/* OTHERS */}
            <Grid size={{ xs: 12, md: 5 }}>
              <Box
                sx={{
                  backdropFilter: "blur(12px)",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "12px",
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                  padding: "30px",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)", // Slight hover effect
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "left",
                    mb: 2,
                  }}
                >
                  OTHERS
                </Typography>

                {othersPoints.map((point) => (
                  <Box
                    key={point.title}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "12px",
                    }}
                  >
                    <Done fontSize="small" sx={{ color: customColors.gold }} />
                    <Typography
                      fontSize="15px"
                      sx={{ mb: 2, ml: "10px", color: "#fff" }}
                    >
                      <b>
                        {point.title}
                        {": "}
                      </b>
                      {point.description}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
