import React from "react";
import { Box, Typography, Button, Stack, Paper } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import UploadIcon from "@mui/icons-material/Upload";
import { useTranslation } from "react-i18next";
import { CheckCircleOutline } from "@mui/icons-material";
import { customColors } from "../../theme";

const TransformListings = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      maxWidth="1200px"
      mx="auto"
      borderRadius={6}
      overflow="hidden"
      boxShadow={3}
      marginBottom={"80px"}
      marginTop={"40px"}
    >
      {/* Left Side Image with Overlay */}
      <Box
        position="relative"
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: 300, lg: "auto" }}
      >
        <Box
          component="img"
          src="https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/realEstateShake.png?alt=media&token=9203a0ba-ef59-4d0a-94ce-73509a009139"
          alt="Real Estate Meeting"
          sx={{ width: "100%", height: "100%", objectFit: "cover" }}
        />

        {/* ✔️ Checklist Overlay on Bottom-Right of the IMAGE */}
        <Paper
          sx={{
            position: "absolute",
            bottom: 30,
            right: 30,
            bgcolor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            p: 3,
            borderRadius: 4,
            width: 260,
            zIndex: 2,
          }}
        >
          {[
            "MLS",
            "INSTANT_RESULTS",
            "FURNITURE_REMOVAL",
            "DESIGN_STYLES",
            "USERS",
          ].map((key, idx) => (
            <Box key={idx} display="flex" alignItems="center" mb={1.2}>
              <CheckCircleOutline sx={{ fontSize: 16, mr: 1 }} />
              <Typography variant="body2">
                {t(`TEXT.FEATURES.${key}`)}
              </Typography>
            </Box>
          ))}
        </Paper>
      </Box>

      {/* Right Side Text Section */}
      <Box
        width={{ xs: "100%", lg: "50%" }}
        bgcolor={customColors.gold}
        p={{ xs: 4, md: 6 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          mb={2}
          sx={{
            bgcolor: "#fff",
            px: 1.9,
            py: 0.8,
            width: "fit-content",
            borderRadius: 10,
          }}
        >
          <RocketLaunchIcon sx={{ fontSize: 18, color: "black" }} />
          <Typography variant="body2" fontWeight={700} color="black">
            {t("BUTTONS.UPLOAD_IMAGE")}
          </Typography>
        </Stack>

        <Typography
          variant="h4"
          fontWeight="900"
          sx={{ color: "#000", lineHeight: 1.3 }}
        >
          {t("TRANSFORM_LISTING.TITLE_LINE_1")}
        </Typography>

        <Typography
          variant="h4"
          mb={2}
          fontWeight="550"
          sx={{ color: "#000", lineHeight: 1.3 }}
        >
          {t("TRANSFORM_LISTING.TITLE_LINE_2")}
        </Typography>

        <Typography
          variant="body1"
          color="black"
          mb={2}
          sx={{ maxWidth: "450px" }}
        >
          {t("TRANSFORM_LISTING.DESCRIPTION")}
        </Typography>

        <Typography
          variant="body1"
          fontWeight={600}
          mb={4}
          color={"black"}
          sx={{ maxWidth: "450px" }}
        >
          {t("TRANSFORM_LISTING.CTA_NOTE")}
        </Typography>

        <Button
          variant="contained"
          size="large"
          endIcon={<UploadIcon />}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          sx={{
            fontWeight: "bold",
            backgroundColor: "#000",
            width: "300px",
            borderRadius: "10px",
            color: "#fff",
            "&:hover": { backgroundColor: "#222" },
          }}
        >
          {t("BUTTONS.UPLOAD_IMAGE")}
        </Button>

        <Typography variant="body2" color="black" align="left" mt={2}>
          {t("TEXT.NO_SIGNUP")}
        </Typography>
      </Box>
    </Box>
  );
};

export default TransformListings;
