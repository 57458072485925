import React from "react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { customColors } from "../theme";

interface Props {
  maxHeigthMobile?: string;
  maxHeigth?: string;
  fullScreen?: boolean;
}

export const Loader: React.FC<Props> = ({
  maxHeigth,
  maxHeigthMobile,
  fullScreen = false,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      sx={{
        p: isMobile ? 2 : 4,
        borderRadius: fullScreen ? 0 : "10px",
        width: fullScreen ? "100vw" : "auto",
        height: fullScreen ? "100vh" : "auto",
        maxWidth: fullScreen ? "100vw" : "1200px",
        minHeight: isMobile ? maxHeigthMobile || "300px" : maxHeigth || "500px",
        margin: fullScreen ? 0 : "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: fullScreen ? customColors.darkGray : "transparent",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
