import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "../../context/AuthContext";
import { managePayment } from "../../api/services/managePaymentService";

export const useManagePayment = () => {
  const { token } = useAuthContext();
  const {
    data,
    isLoading: isManagePaymentLoading,
    isError: isManagePaymentError,
    error,
    refetch,
  } = useQuery({
    queryKey: ["managePayment"],
    queryFn: async () => {
      if (!token) throw new Error("Missing JWT token");

      const headers = { Authorization: `Bearer ${token}` };
      return managePayment(headers);
    },
    enabled: false, // prevents fetching on mount
  });

  return {
    managePaymentRedirectURL: data?.redirect_uri,
    isManagePaymentLoading,
    isManagePaymentError,
    error,
    fetchManagePayment: refetch,
  };
};
