import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { customColors } from "../../theme";
import { CustomButton } from "../CustomButton";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader";
import { useSubscriptionContext } from "../../context/SubscriptionContext";
import { trackOrderCreationSettingsEvent } from "../../utils/tracking";
import { BaseSelect } from "../Filters/BaseSelect";
import { useRoomPropertiesQuery } from "../../hooks/queries/useRoomPropertiesQuery";
import { useAuthContext } from "../../context/AuthContext";
import { HelpOutline } from "@mui/icons-material";

interface Props {
  handleCreateOrder: (params: {
    removeFurniture: boolean;
    roomType: string;
    roomStyle: string;
  }) => void;
  isDisabled: boolean;
  setIsError: (value: React.SetStateAction<boolean>) => void;
  actionText?: string;
  isLoading?: boolean;
  isGenerateMore?: boolean;
}

export const FormPanelV2: React.FC<Props> = ({
  handleCreateOrder,
  isDisabled,
  setIsError,
  actionText,
  isLoading,
  isGenerateMore = false,
}) => {
  const { t } = useTranslation();
  const [orderSetup, setOrderSetup] = useState({
    roomType: "",
    roomStyle: "",
    removeFurniture: true,
  });
  const { roomTypes, roomStyles, isRoomPropsError, isRoomPropsLoading } =
    useRoomPropertiesQuery();

  const navigate = useNavigate();

  const { isSubscriptionLoading, subscriptionResult } =
    useSubscriptionContext();
  const { isUserVerified } = useAuthContext();

  const notEnoughCredits: boolean = isGenerateMore
    ? false
    : !isSubscriptionLoading &&
      subscriptionResult !== undefined &&
      subscriptionResult.plan !== 0 &&
      subscriptionResult?.credits_amount === 0;

  useEffect(() => {
    if (isRoomPropsError) {
      setIsError(true);
      trackOrderCreationSettingsEvent({ event: "Room properties API error" });
    }
    setOrderSetup((prevState) => ({
      ...prevState,
      roomStyle:
        prevState.roomStyle || (roomStyles.length > 0 ? roomStyles[0].id : ""),
      roomType:
        prevState.roomType || (roomTypes.length > 0 ? roomTypes[0].id : ""),
    }));
  }, [setIsError, isRoomPropsError, roomStyles, roomTypes]);

  const handleProcessPhoto = () => {
    if (!isDisabled) {
      handleCreateOrder({
        removeFurniture: orderSetup.removeFurniture,
        roomType: orderSetup.roomType,
        roomStyle: orderSetup.roomStyle,
      });
    }
  };

  const furnitureRemovalField = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "10px",
      }}
    >
      <Checkbox
        checked={orderSetup.removeFurniture}
        onChange={(e) => {
          setOrderSetup({
            ...orderSetup,
            removeFurniture: e.target.checked,
          });
          trackOrderCreationSettingsEvent({
            event: "Furniture removal checkbox changed",
            label: e.target.checked ? "Checked" : "Unchecked",
          });
        }}
        sx={{ color: "#FFF" }}
      />
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        {t("TEXT.UPLOAD_PHOTO.REMOVE_FURNITURE")}
      </Typography>
      <Tooltip title={t("TEXT.UPLOAD_PHOTO.REMOVE_FURNITURE_INFO")} arrow>
        <IconButton size="small" sx={{ ml: 1 }}>
          <HelpOutline fontSize="small" sx={{ color: "#FFF" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const roomPropertiesSelection = (
    <Box
      sx={{
        mb: 2,
        border: "1px solid #ccc",
        borderRadius: "8px",
        p: "15px",
        color: "#fff",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 600, color: "#fff", mb: "10px" }}
      >
        {t("TEXT.UPLOAD_PHOTO.ADD_FURNITURE")}
      </Typography>

      <InputLabel id="room-type-label" sx={{ color: "#fff" }} shrink>
        {t("TEXT.UPLOAD_PHOTO.ROOM_TYPE")}
      </InputLabel>
      <BaseSelect
        value={orderSetup.roomType}
        onChange={(e) => {
          setOrderSetup({ ...orderSetup, roomType: e.target.value });
          trackOrderCreationSettingsEvent({
            event: "Room type change",
            label: e.target.value,
          });
        }}
        menuItems={roomTypes.map((type) => ({
          label: type.name,
          value: type.id,
          locked: isUserVerified
            ? false
            : type.id !== "bed" && type.id !== "living", // lock most room types for unverified users
        }))}
        disabled={roomTypes.length === 0}
      />

      <InputLabel
        id="furniture-style-label"
        sx={{ color: "#fff", mt: "10px" }}
        shrink
      >
        {t("TEXT.UPLOAD_PHOTO.STYLE")}
      </InputLabel>
      <BaseSelect
        value={orderSetup.roomStyle}
        onChange={(e) => {
          setOrderSetup({ ...orderSetup, roomStyle: e.target.value });
          trackOrderCreationSettingsEvent({
            label: e.target.value,
            event: "Room style change",
          });
        }}
        menuItems={roomStyles.map((style) => ({
          label: style.name,
          value: style.id,
        }))}
        disabled={roomStyles.length === 0}
      />
    </Box>
  );

  const outOfPhotos = (
    <Box sx={{ p: "10px 20px" }}>
      <Typography
        variant="subtitle1"
        sx={{ color: "#fff", mb: "10px", textAlign: "center" }}
      >
        {t("TEXT.UPLOAD_PHOTO.NOT_ENOUGH_CREDITS")}
      </Typography>
      <CustomButton
        text={t("TEXT.UPLOAD_PHOTO.NOT_ENOUGH_CREDITS_BUTTON")}
        onClick={() => navigate("/pricing")}
      />
    </Box>
  );

  return notEnoughCredits ? (
    outOfPhotos
  ) : (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {isGenerateMore ? (
        <></>
      ) : (
        <>
          {isRoomPropsLoading || isSubscriptionLoading ? (
            <Loader maxHeigth="250px" maxHeigthMobile="250px" />
          ) : (
            <Box display="flex" flexDirection="column" gap="32px">
              {furnitureRemovalField}
              {roomPropertiesSelection}
            </Box>
          )}
        </>
      )}

      <Box mt={1}>
        <CustomButton
          text={actionText || t("TEXT.UPLOAD_PHOTO.PROCESS_PHOTO")}
          onClick={handleProcessPhoto}
          isDisabled={isDisabled}
          isLoading={isLoading || false}
        />
      </Box>

      <Typography
        sx={{
          color: customColors.silver,
          fontSize: "13px",
          textAlign: "center",
          mb: 2,
        }}
      >
        {"Unlimited variations and downloads included"}
      </Typography>
    </Box>
  );
};
