import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";
import { CssBaseline } from "@mui/material";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { SubscriptionProvider } from "./context/SubscriptionContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CurrentImageProvider } from "./context/CurrentImageContext";
import { SnackbarProvider } from "./context/SnackbarContext";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter } from "react-router-dom/dist";
import ReactGA from "react-ga4";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";

const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error(
    "Root element not found! Ensure your HTML includes an element with id='root'."
  );
}
const root = ReactDOM.createRoot(rootElement);

// Initialize posthog
const posthogPublicKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY || "";
if (!posthogPublicKey) {
  console.error("Posthog public key is missing.");
}
const posthogPublicHost = process.env.REACT_APP_PUBLIC_POSTHOG_HOST;
if (!posthogPublicHost) {
  console.error("Posthog public host is missing.");
}
posthog.init(posthogPublicKey, {
  api_host: posthogPublicHost,
});

// Initialize google analytics
const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string;
if (!googleAnalyticsId) {
  console.error("Google analytics id is missing.");
}
ReactGA.initialize(googleAnalyticsId);

//TODO investigate why this does not work
// Initialize google tag manager
// const googleTagManagerId = process.env
//   .REACT_APP_GOOGLE_TAG_MANAGER_ID as string;
// if (!googleTagManagerId) {
//   console.error("Google tag manager id is missing.");
// }
// const tagManagerArgs = {
//   gtmId: googleTagManagerId,
// };
// TagManager.initialize(tagManagerArgs);

// Setup query client for react useQuery
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

// Initialize stripe
const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string;
if (!stripeKey) {
  console.error("Stripe publishable key is missing.");
}
const stripePromise = loadStripe(stripeKey);

// Initialize feature flag server
const growthBookSdkClientKey = process.env.REACT_APP_GROWTH_BOOK_CLIENT_KEY;
if (!growthBookSdkClientKey) {
  console.error("Growthbook sdk client key is missing.");
}
const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: growthBookSdkClientKey,
  enableDevMode: process.env.NODE_ENV !== "production",
});
growthbook.init({ streaming: true });

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <GrowthBookProvider growthbook={growthbook}>
          <PostHogProvider client={posthog}>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                <CurrentImageProvider>
                  <SnackbarProvider>
                    <SubscriptionProvider>
                      <BrowserRouter>
                        <Elements stripe={stripePromise}>
                          <CssBaseline />
                          <App />
                        </Elements>
                      </BrowserRouter>
                    </SubscriptionProvider>
                  </SnackbarProvider>
                </CurrentImageProvider>
              </QueryClientProvider>
            </AuthProvider>
          </PostHogProvider>
        </GrowthBookProvider>
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
