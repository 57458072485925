import { ENDPOINTS } from "../endpoints";

export type GetDownloadResponse = {
  hd_processing: boolean;
  image_src: string;
};

export interface GetDownloadParams {
  orderId: string;
  imageId: string;
  hdImage: boolean;
}

export const getDownload = async (
  { orderId, imageId, hdImage }: GetDownloadParams,
  headers: { Authorization: string }
): Promise<GetDownloadResponse> => {
  try {
    const url = new URL(
      `${ENDPOINTS.ORDER}/${orderId}${ENDPOINTS.IMAGES}/${imageId}${ENDPOINTS.DOWNLOAD}`
    );
    url.searchParams.append("hdImage", hdImage.toString());

    const response = await fetch(url.toString(), {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error("Failed to get download");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting download:", error);
    throw error;
  }
};
