import { LockOpenOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../CustomButton";
import { FC } from "react";
import { trackButtonClickEvent } from "../../utils/tracking";

const PhotoAdPanel: FC = () => {
  const navigate = useNavigate();
  return (
    <Grid size={{ xs: 12, md: 2.5 }}>
      <Box
        sx={{
          flex: 1,
          padding: "0px",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          alignContent: "left",
          alignItems: "left",
        }}
      >
        <Box>
          <Typography
            sx={{
              border: "1px solid rgba(255, 255, 255, 0.2)",
              display: "inline-block",
              borderRadius: "12px",
              padding: "6px 12px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {t("TEXT.PHOTOS.STARTING_PRICE")}
          </Typography>

          <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("TEXT.PHOTOS.FULL_VERSION_INCLUDES")}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "10px" }}>
              {t("TEXT.PHOTOS.REMOVE_WATERMARK")}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "5px" }}>
              {t("TEXT.PHOTOS.HIGH_RESOLUTION")}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "5px" }}>
              {t("TEXT.PHOTOS.HIGH_RESOLUTION_DOWNLOAD")}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "5px" }}>
              {t("TEXT.PHOTOS.ALL_ROOM_TYPES")}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "5px" }}>
              {t("TEXT.PHOTOS.FURNITURE_REMOVAL")}
            </Typography>
          </Box>

          <CustomButton
            text={t("TEXT.PHOTOS.UNLOCK_FEATURES")}
            onClick={() => {
              trackButtonClickEvent({
                label: "Unlock all features after free generation",
              });

              navigate(`/pricing`);
            }}
            icon={<LockOpenOutlined />}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default PhotoAdPanel;
