import React from "react";
import { Box, Typography, Container } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Grid from "@mui/material/Grid2";
import { customColors } from "../../../theme";
import { useTranslation } from "react-i18next";

export const StatsSection: React.FC = () => {
  const { t } = useTranslation();
  const stats = ["BUYER_INTEREST", "FASTER_SALES", "HIGHER_OFFERS"];

  return (
    <Box sx={{ padding: "40px 0", maxWidth: "1200px", margin: "0 auto" }}>
      <Grid
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" }, // 1 column on mobile, 3 columns on larger screens
          gap: { xs: 1, md: 0 }, // Larger gap on mobile
        }}
      >
        {stats.map((stat, index) => (
          <Box
            key={index}
            sx={{
              padding: "20px", // Padding for mobile and desktop
              textAlign: "center", // Center-align everything on all screen sizes

              borderRight: {
                md:
                  index !== stats.length - 1
                    ? "1px solid rgba(255, 255, 255, 0.2)"
                    : "none", // Border between columns on larger screens
              },
            }}
          >
            {/* Title and Icon - Centered */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left", // Center title and icon
                ml: { xs: "40px", md: "30px" },
                mb: 2,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", textAlign: "center" }} // Center title
              >
                {t(`TEXT.STATS.${stat}.TITLE`)}
              </Typography>
              <ArrowUpwardIcon
                sx={{ color: customColors.blue, fontSize: "20px", ml: 1 }}
              />
            </Box>

            {/* Percentage - Centered */}
            <Typography
              variant="h2"
              sx={{
                fontWeight: "bold",
                textAlign: { xs: "center", md: "left" },
                mb: "10px",
                ml: { md: "30px" },
              }}
            >
              {t(`TEXT.STATS.${stat}.PERCENTAGE`)}
            </Typography>

            {/* Description - Centered with limited width */}
            <Container
              sx={{
                mt: 1,
                maxWidth: "320px", // Limit width of description
                textAlign: { xs: "center", md: "left" },
                margin: "0 auto", // Center the description
              }}
            >
              <Typography variant="body1">
                {t(`TEXT.STATS.${stat}.DESCRIPTION`)}
              </Typography>
            </Container>

            {/* Dividing Line */}
            {index !== stats.length - 1 && (
              <Box
                sx={{
                  marginTop: 3, // Add space above the dividing line
                  borderBottom: {
                    xs: "1px solid rgba(255, 255, 255, 0.2)",
                    md: "1px solid transparent",
                  }, // Gray dividing line
                  width: "90%", // Make the line not span the full width
                  margin: "30px auto 0", // Center the line with spacing above and below
                }}
              />
            )}
          </Box>
        ))}
      </Grid>
    </Box>
  );
};
