import { ENDPOINTS } from "../endpoints";

export interface PaymentCustomerEmailParams {
  sessionId: string | null;
}

export interface PaymentCustomerEmailResponse {
  email_address: string;
}

export const getPaymentCustomerEmail = async ({
  sessionId,
}: PaymentCustomerEmailParams): Promise<PaymentCustomerEmailResponse> => {
  if (!sessionId) {
    throw new Error("Session ID is required to fetch payment customer email.");
  }

  const endpoint = `${ENDPOINTS.PAYMENT_CUSTOMER_EMAIL}?sessionId=${encodeURIComponent(sessionId)}`;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to get PaymentCustomerEmail. Status: ${response.status}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error getting PaymentCustomerEmail:", error);
    throw error;
  }
};
