import React from "react";

import Grid from "@mui/material/Grid2";
import { PaymentForm } from "./PaymentForm";

export const UpdatePaymentDetails = () => {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        alignItems: "center",
        pt: "30px",
        pb: "30px",
        margin: "0 auto",
        alignContent: "center",
      }}
    >
      <PaymentForm actionText="Update" />
    </Grid>
  );
};
