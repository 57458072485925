import { ENDPOINTS } from "../endpoints";

export interface PaymentResponse {
  redirect_uri: string;
}

export const managePayment = async (headers: {
  Authorization: string;
}): Promise<PaymentResponse> => {
  const endpoint = ENDPOINTS.MANAGE_SUBSCRIPTION;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to get manage payment");
    }

    const data: PaymentResponse = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting manage payment:", error);
    throw error;
  }
};
