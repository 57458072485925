import React, { useState, useEffect } from "react";
import { PLACEHOLDER_IMAGE } from "../../constants";

interface ChangingImageProps {
  beforeSrc: string;
  afterSrc: string;
}

const ChangingImage: React.FC<ChangingImageProps> = ({
  beforeSrc,
  afterSrc,
}) => {
  const [hovered, setHovered] = useState(false);
  const [isAfterLoaded, setIsAfterLoaded] = useState(false);

  useEffect(() => {
    // Preload the "after" image to cache it
    const afterImage = new Image();
    afterImage.src = afterSrc;
    afterImage.onload = () => setIsAfterLoaded(true);
  }, [afterSrc]);

  return (
    <img
      src={hovered && isAfterLoaded ? afterSrc : beforeSrc}
      alt="Gallery Image"
      style={{
        width: "100%",
        height: "100%",
        maxWidth: "400px",
        maxHeight: "200px",
        borderRadius: "10px",
        objectFit: "cover",
        transition: "opacity 0.5s ease-in-out",
        opacity: hovered ? 0.9 : 1, // Optional opacity effect on hover
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onError={(e) => {
        (e.target as HTMLImageElement).src = PLACEHOLDER_IMAGE;
      }}
    />
  );
};

export default ChangingImage;
