import { useState } from "react";
import {
  getOrderStatus,
  GetOrderStatusResponse,
} from "../api/services/orderStatusService";
import { useAuthContext } from "../context/AuthContext";

import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { getOrderStatusV2 } from "../api/services/orderStatusServiceV2";

interface OrderStatusParams {
  orderId: string;
}

// TODO refactor it to useQuery
export const useGetOrderStatus = () => {
  const { token } = useAuthContext();
  const [orderStatus, setOrderStatus] = useState<GetOrderStatusResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const isNewAPI = useFeatureIsOn("new-ai-api");

  const handleGetOrderStatus = async (params: OrderStatusParams) => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      // Check if the JWT token exists
      if (!token) {
        throw new Error("Missing JWT token");
      }

      // Create headers including the JWT token in the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Pass headers to the API call and get the response
      const response: GetOrderStatusResponse = await (isNewAPI
        ? getOrderStatusV2(params, headers)
        : getOrderStatus(params, headers));

      // Set success state and store order_id from the response
      setIsSuccess(true);
      setOrderStatus(response);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleGetOrderStatus,
    orderStatus,
    isLoading,
    isError,
    isSuccess,
  };
};
