export const QUERY_PARAMS = {
  billing: "billing",
  plan: "plan",
  source: "source",
  order: "order",
  update: "update",
};

export const PLACEHOLDER_IMAGE =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/640px-Placeholder_view_vector.svg.png";

export const POSTHOC_KEYS = {
  heroSectionButton: "hero-section-button",
};
