import React, { useEffect } from "react";
import { Container } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import { Navbar } from "./components/Home/Header/Nav";
import { Footer } from "./components/Footer/Footer";
import { useSubscriptionContext } from "./context/SubscriptionContext";
import { useAuthContext } from "./context/AuthContext";
import { useGTM } from "./hooks/useGTM";
import { Loader } from "./components/Loader";
import { PATHS } from "./routes/routes";
import { SubscriptionType } from "./api/services/userSubscriptionService";
import { ConditionalRoute } from "./routes/ConditionalRoute";
import { customColors } from "./theme";
import { Home } from "./components/Home/Home";
import { PricingPlan } from "./components/Pricing/PricingPage";
import { Login } from "./components/AuthComponents/Login";
import { Register } from "./components/AuthComponents/Register";
import { CheckYourEmail } from "./components/AuthComponents/CheckYourEmail";
import { ForgotPassword } from "./components/AuthComponents/ForgotPassword";
import { TermsOfUse } from "./components/Legal/TermsOfUse";
import { PrivacyPolicy } from "./components/Legal/PrivacyPolicy";
import { Checkout } from "./components/Checkout/Checkout";
import { PhotoPage } from "./components/Photos/PhotoPage";
import { UpdatePaymentDetails } from "./components/Checkout/UpdatePaymentDetails";
import { PaymentStatus } from "./components/Pricing/PaymentStatus";
import { MyPhotos } from "./components/Photos/MyPhotos";
import { UploadPhoto } from "./components/Photos/UploadPhoto";
import { Settings } from "./components/Settings/Settings";
import { Gallery } from "./components/Gallery/Gallery";

const App: React.FC = () => {
  useGTM(); // Tracks pageviews

  useEffect(() => {
    document.body.style.paddingTop = "env(safe-area-inset-top)";
  }, []);

  const { isUserVerified, isAuthLoading } = useAuthContext();
  const { subscriptionResult } = useSubscriptionContext();

  if (isAuthLoading) {
    return <Loader fullScreen />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: customColors.darkGray,
      }}
    >
      <Navbar />
      <Container
        disableGutters
        maxWidth={false}
        sx={{ flex: 1, padding: 0, margin: 0 }}
      >
        <Routes>
          {/* 🟢 Public Routes */}
          <Route path={PATHS.home} element={<Home />} />
          <Route path={PATHS.pricing} element={<PricingPlan />} />
          <Route path={PATHS.login} element={<Login />} />
          <Route path={PATHS.signup} element={<Register />} />
          <Route path={PATHS.checkYourEmail} element={<CheckYourEmail />} />
          <Route path={PATHS.forgotPassword} element={<ForgotPassword />} />
          <Route path={PATHS.termsOfUse} element={<TermsOfUse />} />
          <Route path={PATHS.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={PATHS.checkout} element={<Checkout />} />
          <Route path={PATHS.photo} element={<PhotoPage />} />
          <Route
            path={PATHS.updatePayment}
            element={<UpdatePaymentDetails />}
          />
          <Route
            path={`${PATHS.paymentStatus}/:status`}
            element={<PaymentStatus />}
          />

          {/* 🔒 Conditional Routes */}

          {/* Allow My Photos and upload only for logged in users with cancelled or paid subscription */}
          <Route
            element={
              <ConditionalRoute
                condition={
                  isUserVerified &&
                  (!!subscriptionResult?.canceled ||
                    subscriptionResult?.plan !== SubscriptionType.FREE)
                }
              />
            }
          >
            <Route path={PATHS.myPhotos} element={<MyPhotos />} />
            <Route path={PATHS.uploadPhoto} element={<UploadPhoto />} />
          </Route>

          {/* Allow Settings for logged in users*/}
          <Route element={<ConditionalRoute condition={isUserVerified} />}>
            <Route path={PATHS.settings} element={<Settings />} />
          </Route>

          {/* Allow Gallery for all users except paid ones*/}
          <Route
            element={
              <ConditionalRoute
                condition={
                  !isUserVerified ||
                  !subscriptionResult ||
                  subscriptionResult?.plan === SubscriptionType.FREE
                }
              />
            }
          >
            <Route path={PATHS.gallery} element={<Gallery />} />
          </Route>

          {/* 🔄 Catch-all: Redirect unknown routes to Home */}
          <Route path="*" element={<Navigate to={PATHS.home} />} />
        </Routes>
      </Container>
      <Footer />
    </div>
  );
};

export default App;
