import { useSubscriptionContext } from "../../context/SubscriptionContext";
import {
  createOrder,
  PostOrderResponse,
} from "../../api/services/orderService";
import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../context/AuthContext";
import { createOrderV2 } from "../../api/services/orderServiceV2";
import { AiVersion } from "../../api/services/orderResultService";

interface NewOrderParams {
  image: File;
  removeFurniture: boolean;
  roomType: string;
  roomStyle: string;
  demoId: string | null;
}

interface OldOrderParams {
  image: File;
  removeFurniture: boolean;
  spaceName: string;
  styleId: string;
  widgetId: string;
  demoId: string | null;
}

type UseCreateOrderMutationProps = {
  aiVersion: AiVersion;
};

export const useCreateOrderMutation = ({
  aiVersion,
}: UseCreateOrderMutationProps) => {
  const { token } = useAuthContext();
  const { refetchSubscription } = useSubscriptionContext();
  const mutation = useMutation<
    PostOrderResponse,
    Error,
    NewOrderParams | OldOrderParams
  >({
    mutationFn: async (params) => {
      if (!token) {
        throw new Error("Missing JWT token");
      }
      const headers = { Authorization: `Bearer ${token}` };
      return aiVersion === AiVersion.NEW
        ? createOrderV2(params as NewOrderParams, headers)
        : createOrder(params as OldOrderParams, headers);
    },
    onSuccess: () => refetchSubscription(),
  });

  return {
    handleCreateOrder: mutation.mutate,
    isOrderLoading: mutation.isPending,
    isOrderError: mutation.isError,
    isOrderSuccess: mutation.isSuccess,
    orderId: mutation.data?.order_id ?? null,
  };
};
