import { useSubscriptionContext } from "../../context/SubscriptionContext";
import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../context/AuthContext";
import {
  createOrderGenerateMoreV2,
  GenerateMoreParams,
} from "../../api/services/orderGenerateMoreServiceV2";
import { AiVersion } from "../../api/services/orderResultService";
import { createOrderGenerateMore } from "../../api/services/orderGenerateMoreService";

type UseGenerateMoreMutationProps = {
  aiVersion: AiVersion;
};

export const useGenerateMoreMutation = ({
  aiVersion,
}: UseGenerateMoreMutationProps) => {
  const { token } = useAuthContext();
  const { refetchSubscription } = useSubscriptionContext();

  const mutation = useMutation<boolean, Error, GenerateMoreParams>({
    mutationFn: async ({ orderId }) => {
      if (!token) {
        throw new Error("Missing JWT token");
      }
      const headers = { Authorization: `Bearer ${token}` };
      return aiVersion === AiVersion.NEW
        ? createOrderGenerateMoreV2({ orderId }, headers)
        : createOrderGenerateMore({ orderId }, headers);
    },
    onSuccess: () => refetchSubscription(),
  });

  return {
    handleGenerateMoreImages: mutation.mutate,
    isGenerateMoreLoading: mutation.isPending,
    isGenerateMoreError: mutation.isError,
    isGenerateMoreSuccess: mutation.isSuccess,
    isOrderSucess: mutation.data,
  };
};
