import { useQuery } from "@tanstack/react-query";
import { getPaymentCustomerEmail } from "../../api/services/paymentCustomerEmailService";

type Props = {
  sessionId: string | null;
};

export const usePaymentCustomerEmailQuery = ({ sessionId }: Props) => {
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ["getPaymentCustomerEmail", sessionId],
    queryFn: () => getPaymentCustomerEmail({ sessionId }),
    enabled: !!sessionId,
  });

  return {
    paymentEmail: data?.email_address || "",
    isPaymentEmailLoading: isLoading,
    isPaymentEmailError: isError,
    isPaymentEmailSuccess: isSuccess,
  };
};
