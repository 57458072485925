import React, { useState, useEffect } from "react";
import countries from "./countries.json";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { customColors } from "../../theme";
import { useTranslation } from "react-i18next";
import { trackButtonClickEvent } from "../../utils/tracking";

interface Country {
  code: string;
  name: string;
  file: string;
}

const FlagSelector: React.FC = () => {
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const stored = localStorage.getItem("selectedCountry");
    if (stored) {
      setSelectedCountry(JSON.parse(stored));
    } else {
      const defaultCountry = countries.find((c) => c.code === "us");
      setSelectedCountry(defaultCountry || null);
    }
  }, []);

  const handleSelect = (country: Country) => {
    setSelectedCountry(country);
    localStorage.setItem("selectedCountry", JSON.stringify(country));
    trackButtonClickEvent({
      label: "Country selected",
      extraParams: { country: country.toString() },
    });
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => {
          setOpen(true);
          trackButtonClickEvent({ label: "Country Selector Clicked" });
        }}
        sx={{
          width: 36,
          height: 36,
          borderRadius: "50%",
          padding: 0,
          margin: "0 10px",
          backgroundColor: customColors.darkGray,
          overflow: "hidden",
        }}
      >
        {selectedCountry && (
          <img
            src={selectedCountry.file}
            alt={selectedCountry.name}
            width="36"
            height="36"
            style={{ objectFit: "cover" }}
          />
        )}
      </IconButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
        slotProps={{
          paper: {
            sx: {
              borderRadius: "15px",
              overflow: "hidden",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: customColors.mediumGray,
            borderRadius: "40",
            height: "50px",
          }}
        >
          <Typography variant="body1">{t("TEXT.SELECT_COUNTRY")}</Typography>
          <IconButton sx={{ color: "#fff" }} onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          dividers
          sx={{ backgroundColor: customColors.darkGray, borderRadius: "10" }}
        >
          <Box display={"flex"} flexDirection={"row"}>
            {countries.map((country) => (
              <Box
                sx={{ width: "100%", margin: "10px", height: "200px" }}
                key={country.code}
              >
                <Box
                  onClick={() => handleSelect(country)}
                  sx={{
                    cursor: "pointer",
                    borderRadius: 2,
                    padding: 2,
                    height: "100%",
                    textAlign: "center",
                    "&:hover": {
                      backgroundColor: customColors.lightGray,
                    },
                  }}
                >
                  <img
                    src={country.file}
                    alt={country.name}
                    width={120}
                    height={120}
                    style={{ marginBottom: 6, borderRadius: 10 }}
                  />
                  <Typography variant="body1">{country.name}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FlagSelector;
