import { useQuery } from "@tanstack/react-query";
import { getSpaces } from "../../api/services/spaceService";

// TODO CLEAN UP OLD API
export const useSpacesQuery = () => {
  const {
    data: spaces = [],
    isLoading: isSpacesLoading,
    isError: isSpacesError,
  } = useQuery({
    queryKey: ["getSpaces"],
    queryFn: getSpaces,
  });

  return { spaces, isSpacesLoading, isSpacesError };
};
