import { useQuery } from "@tanstack/react-query";
import {
  getRoomProperties,
  RoomProperties,
} from "../../api/services/roomPropertiesService";

const DEFAULT_ROOM_PROPERTIES: RoomProperties = {
  room_types: [],
  room_styles: [],
};

export const useRoomPropertiesQuery = () => {
  const {
    data = DEFAULT_ROOM_PROPERTIES,
    isLoading: isRoomPropsLoading,
    isError: isRoomPropsError,
  } = useQuery({
    queryKey: ["getRoomProperties"],
    queryFn: getRoomProperties,
  });

  const { room_types: roomTypes, room_styles: roomStyles } = data;

  return {
    roomTypes,
    roomStyles,
    isRoomPropsLoading,
    isRoomPropsError,
  };
};
