import React, { useCallback, useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { customColors } from "../../theme";
import { ImagePanel } from "./UploadPhotoImagePanel";
import { Loader } from "../Loader";
import { ErrorComponent } from "../ErrorComponent";

import { useCurrentImageContext } from "../../context/CurrentImageContext";
import { useNavigate } from "react-router-dom";
import { QUERY_PARAMS } from "../../constants";
import imageCompression from "browser-image-compression";
import { SimpleImageData } from "../Gallery/imageLinks";
import { useAuthContext } from "../../context/AuthContext";
import {
  EventAction,
  EventCategory,
  trackEvent,
  trackPhotoUploadEvent,
} from "../../utils/tracking";
import { FormPanelV2 } from "./UploadPhotoFormPanelV2";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useTranslation } from "react-i18next";
import { useCreateOrderMutation } from "../../hooks/mutations/useCreateOrderMutation";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { AiVersion } from "../../api/services/orderResultService";
import { FormPanel } from "./UploadPhotoFormPanel";

interface Props {
  onPhotoSubmited?: (orderId: string) => void;
}

export const UploadPhoto: React.FC<Props> = ({ onPhotoSubmited }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();

  const { user } = useAuthContext();

  const isNewAPI = useFeatureIsOn("new-ai-api");

  const isLoggedInUser = (user && user.emailVerified) || false;
  const { showSnackbar } = useSnackbarContext();

  const { handleCreateOrder, isOrderSuccess, isOrderError, orderId } =
    useCreateOrderMutation({
      aiVersion: isNewAPI ? AiVersion.NEW : AiVersion.OLD,
    });

  const [orderSent, setOrderSent] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isCompressing, setIsCompressing] = useState<boolean>(false);

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [demoId, setDemoId] = useState<string | null>(null);
  const { setCurrentImage } = useCurrentImageContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (isOrderSuccess && orderId) {
      if (onPhotoSubmited) {
        onPhotoSubmited(orderId);
      } else {
        const params = new URLSearchParams();
        params.append(QUERY_PARAMS.order, orderId);
        navigate(`/photo?${params.toString()}`);
      }
    }
  }, [isOrderSuccess, navigate, onPhotoSubmited, orderId]);

  // Handle file upload from device
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const allowedFormats = ["image/jpeg", "image/png", "image/webp"]; // Allowed file types

      // Check if the file format is allowed
      if (!allowedFormats.includes(file.type)) {
        showSnackbar({
          message: t("TEXT.WHY_US.OTHERS.INVALID_FILE_FORMAT"),
          color: "error",
        });

        return; // Stop further processing
      }
      setDemoId(null);
      setIsCompressing(true); // Set loading to true when image compression starts

      const options = {
        maxSizeMB: 1, // Maximum file size in MB
        maxWidthOrHeight: 1920, // Resize the image to fit within this dimension
        useWebWorker: true, // Use Web Workers for better performance
        fileType: "image/webp", // Convert to WebP format
      };

      try {
        const compressedFile = await imageCompression(file, options);
        setSelectedImage(compressedFile);
        setCurrentImage(compressedFile);
      } catch (error) {
        trackPhotoUploadEvent({
          isLoggedInUser,
          event: "File upload",
          label: "Error during external file upload",
        });
        showSnackbar({
          message: t("TEXT.AUTH.ERROR_OCCURRED"),
          color: "error",
        });
        console.error("Error during image compression:", error);
      } finally {
        trackPhotoUploadEvent({
          isLoggedInUser,
          event: "File upload",
          label: "File uploaded successfully",
        });
        setIsCompressing(false); // Set loading to false once compression finishes
      }
    }
  };

  // Handle image selection from example images
  const handleImageSelect = async (image: SimpleImageData) => {
    try {
      setIsCompressing(true); // Indicate processing

      // Fetch the sample image as a blob
      const response = await fetch(image.url);
      const blob = await response.blob();

      // Convert the blob into a File object
      const file = new File([blob], `${image.id}.jpg`, {
        type: "image/jpeg",
      });

      // Update state with the selected image
      setSelectedImage(file);
      setCurrentImage(file);
      setDemoId(image.id); // Mark it as a demo image
    } catch (error) {
      trackPhotoUploadEvent({
        isLoggedInUser,
        event: "Sample Image selection",
        label: "Error during selection",
      });
      showSnackbar({
        message: t("TEXT.AUTH.ERROR_OCCURRED"),
        color: "error",
      });
      console.error("Error selecting demo image:", error);
    } finally {
      trackPhotoUploadEvent({
        isLoggedInUser,
        event: "Sample Image selection",
        label: "Sample image selected successfully",
      });

      setIsCompressing(false);
    }
  };

  const handleRemoveImage = () => {
    trackPhotoUploadEvent({
      isLoggedInUser,
      event: "Entry form image selection removed",
    });

    setSelectedImage(null);
    setDemoId(null);
    setCurrentImage(null);
  };

  const createOrderHandlerV2 = useCallback(
    ({
      removeFurniture,
      roomType,
      roomStyle,
    }: {
      removeFurniture: boolean;
      roomType: string;
      roomStyle: string;
    }) => {
      trackEvent({
        category: EventCategory.OrderCreate,
        action: EventAction.Submit,
        label: isLoggedInUser ? "PAID" : "FREE",
        extraParams: {
          removeFurniture: removeFurniture.toString(),
          roomType,
          roomStyle,
        },
      });
      setOrderSent(true);
      selectedImage &&
        handleCreateOrder({
          image: selectedImage,
          removeFurniture,
          roomType,
          roomStyle,
          demoId,
        });
    },
    [demoId, handleCreateOrder, isLoggedInUser, selectedImage]
  );

  const createOrderHandler = useCallback(
    (
      removeFurniture: boolean,
      spaceName: string,
      styleId: string,
      widgetId?: string
    ) => {
      trackEvent({
        category: EventCategory.OrderCreate,
        action: EventAction.Submit,
        label: isLoggedInUser ? "PAID" : "FREE",
        extraParams: {
          removeFurniture: removeFurniture.toString(),
          spaceName: spaceName,
          styleId: styleId,
        },
      });
      setOrderSent(true);
      selectedImage &&
        handleCreateOrder({
          image: selectedImage,
          removeFurniture,
          spaceName,
          styleId,
          widgetId: widgetId || "65b4e95ec2b80c47dfe933ab",
          demoId,
        });
    },
    [demoId, handleCreateOrder, isLoggedInUser, selectedImage]
  );

  const imagePanel = ImagePanel({
    selectedImage,
    handleRemoveImage,
    handleImageSelect,
    handleFileUpload,
    isCompressing,
  });

  const formPanel = (
    <Grid size={{ xs: 12, md: 4 }}>
      {isNewAPI
        ? FormPanelV2({
            handleCreateOrder: createOrderHandlerV2,
            isDisabled: !selectedImage,
            setIsError,
          })
        : FormPanel({
            handleCreateOrder: createOrderHandler,
            isDisabled: !selectedImage,
            setIsError,
          })}
    </Grid>
  );

  return (
    <Box
      sx={{
        p: isMobile ? 2 : 4,
        backgroundColor: customColors.semiDarkGray,
        color: "#fff",
        borderRadius: "10px",
        maxWidth: "1200px",
        minHeight: isMobile ? "300px" : "500px",
        margin: "0 auto",
        marginBottom: "40px",
      }}
    >
      {orderSent ? (
        <Loader />
      ) : isError || isOrderError ? (
        <ErrorComponent />
      ) : (
        <Grid container spacing={isMobile ? 0 : 4}>
          {isMobile ? (
            <>
              {imagePanel} {formPanel}
            </>
          ) : (
            <>
              {formPanel}
              {imagePanel}{" "}
            </>
          )}
        </Grid>
      )}
    </Box>
  );
};
