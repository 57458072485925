import { useSearchParams } from "react-router-dom";
import { SubscriptionPlan } from "../components/Pricing/types";
import { packagesPrices } from "../components/Pricing/constants";
import { CycleType } from "../api/services/userSubscriptionService";
import { useEffect } from "react";

type Props = {
  isPaymentSuccessful: boolean;
};

export const useTrackPurchaseEvent = ({ isPaymentSuccessful }: Props) => {
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan") as SubscriptionPlan | null;
  const cycle = searchParams.get("cycle") as CycleType | null;

  // Determine the purchase value
  const conversionValue =
    cycle && plan
      ? cycle.toString() === "monthly"
        ? packagesPrices[1]?.[plan]?.price
        : packagesPrices[2]?.[plan]?.billedYearly
      : -1;

  useEffect(() => {
    if (isPaymentSuccessful && conversionValue > 0) {
      window.dataLayer = window.dataLayer || [];

      // Check if the purchase event has already been pushed
      const isAlreadyTracked = window.dataLayer.some(
        (event) => event.event === "purchase"
      );

      if (!isAlreadyTracked) {
        window.dataLayer.push({
          event: "purchase",
          value: conversionValue,
          subscription_plan: plan,
          subscription_cycle: cycle,
        });
      }
    }
  }, [isPaymentSuccessful, plan, cycle, conversionValue]);
};
