import { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid2";

import { PricingToggle } from "./PricingToggle";
import { PricingCard } from "./PricingCard";
import { PricingCardCompact } from "./PricingCardCompact";
import { packagesPrices } from "./constants";
import { SubscriptionType } from "../../api/services/userSubscriptionService";
import { CycleType } from "../../api/services/userSubscriptionService";
import { Loader } from "../Loader";
import { useSubscriptionContext } from "../../context/SubscriptionContext";
import { useAuthContext } from "../../context/AuthContext";
import { useManagePayment } from "../../hooks/queries/useManagePayment";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useCreatePaymentMutation } from "../../hooks/mutations/useCreatePaymentMutation";
import { TFunction } from "i18next";
import { trackPaymentEvent } from "../../utils/tracking";
import { ConfirmationDialog } from "../ConfirmationDialog";
import RatingCard from "../RatingCard";

export type PlanDetails = {
  title: string;
  description: string;
  photos: string;
  price: string;
  pricePhoto: string;
  yearlyPrice: string;
  features: string[];
  billedYearly: string;
  mostPopular: boolean | undefined;
};

export const PricingPlan = () => {
  const [billing, setBilling] = useState<CycleType>(CycleType.YEARLY);
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarContext();
  const { isUserVerified } = useAuthContext();
  const { subscriptionResult, isSubscriptionLoading } =
    useSubscriptionContext();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
  const [selectedBilling, setSelectedBilling] = useState<number | null>(null);

  const {
    handleCreatePayment,
    createPaymentRedirectURL,
    isCreatePaymentLoading,
    isCreatePaymentError,
  } = useCreatePaymentMutation();

  const {
    managePaymentRedirectURL,
    isManagePaymentLoading,
    isManagePaymentError,
    fetchManagePayment,
  } = useManagePayment();

  const pricingPlans = getPricingPlans(t);

  const handleConfirm = () => {
    setOpenDialog(false);
    if (selectedPlan !== null && selectedBilling !== null) {
      handleCreatePayment({ plan: selectedPlan, cycle: selectedBilling });
      trackPaymentEvent({
        event: "Plan change on pricing page",
        label: `${selectedPlan} ${selectedBilling}`,
      });
    }
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  const onPlanSelected = (billing: CycleType, plan: SubscriptionType) => {
    if (
      isUserVerified &&
      subscriptionResult &&
      plan === subscriptionResult?.plan &&
      billing === subscriptionResult.cycle &&
      !subscriptionResult.canceled
    ) {
      trackPaymentEvent({ event: "Manage payment" });
      fetchManagePayment();
    } else {
      if (
        isUserVerified &&
        subscriptionResult &&
        subscriptionResult.plan !== 0
      ) {
        setSelectedPlan(plan);
        setSelectedBilling(billing);
        setOpenDialog(true);
      } else {
        trackPaymentEvent({
          event: "Plan selected on pricing page",
          label: `${plan} ${billing}`,
        });
        handleCreatePayment({ plan, cycle: billing });
      }
    }
  };

  const plansToShow =
    billing === CycleType.MONTHLY
      ? pricingPlans[CycleType.MONTHLY]
      : pricingPlans[CycleType.YEARLY];

  useEffect(() => {
    if (isCreatePaymentError) {
      showSnackbar({
        message: t("TEXT.PAYMENT.ERRORS.PAYMENT_FAILED"),
        color: "error",
      });
    }
    if (createPaymentRedirectURL) {
      window.location.href = createPaymentRedirectURL;
    }
  }, [createPaymentRedirectURL, isCreatePaymentError, showSnackbar, t]);

  useEffect(() => {
    if (isManagePaymentError) {
      showSnackbar({
        message: t("TEXT.PAYMENT.ERRORS.MANAGE_PAYMENT_FAILED"),
        color: "error",
      });
    }
    if (managePaymentRedirectURL) {
      window.location.href = managePaymentRedirectURL;
    }
  }, [isManagePaymentError, managePaymentRedirectURL, showSnackbar, t]);

  const getPricingCardButtonText = (
    plan: SubscriptionType,
    cycle: CycleType,
    canceled: boolean
  ): string => {
    if (canceled) {
      if (
        plan === subscriptionResult?.plan &&
        cycle === subscriptionResult.cycle
      ) {
        return t("TEXT.SETTINGS.RENEW");
      }
      return (
        t("TEXT.PRICING.RENEW_AND") +
        getPricingCardButtonText(plan, cycle, false)
      );
    }
    if (isUserVerified && subscriptionResult && subscriptionResult.plan !== 0) {
      if (plan > subscriptionResult?.plan) {
        return t("BUTTONS.UPGRADE");
      }
      if (plan < subscriptionResult?.plan) {
        return t("BUTTONS.DOWNGRADE");
      }
      if (plan === subscriptionResult?.plan) {
        if (cycle !== subscriptionResult.cycle) {
          return t("BUTTONS.CHANGE_PERIOD");
        }
        return t("BUTTONS.MANAGE_PAYMENT");
      }
    }
    return t("BUTTONS.GET_STARTED");
  };

  return isSubscriptionLoading ||
    isCreatePaymentLoading ||
    createPaymentRedirectURL ||
    isManagePaymentLoading ||
    managePaymentRedirectURL ? (
    <Loader />
  ) : (
    <Box
      sx={{
        background:
          "linear-gradient(to bottom, rgba(62, 67, 71, 1) 0%, rgba(62, 67, 71, 0) 100%)",
        alignItems: "top",
        alignContent: "top",
        justifyContent: "center",
        display: "flex",
        minHeight: "950px",
        width: "100%",
      }}
    >
      <Box
        p={isMobile ? "15px 12px 20px 12px" : "20px 40px 100px 40px"}
        sx={{
          maxWidth: "1400px",
        }}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between" // This will push items to opposite sides
          alignItems={isMobile ? "center" : "top"}
          sx={{
            marginBottom: isMobile ? "0" : "1rem",
            marginTop: isMobile ? 0 : "30px",
          }}
        >
          <Box
            alignItems={isMobile ? "center" : "top"}
            sx={{ mb: "10px", mt: "0px" }}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              component="div"
              fontWeight="bold"
              gutterBottom
              align={isMobile ? "center" : "left"}
              marginTop={"10px"}
            >
              {t("TEXT.PRICING.TITLE")}
            </Typography>
            <Typography
              variant={isMobile ? "body1" : "h5"}
              component="div"
              gutterBottom
              align={isMobile ? "center" : "left"}
              mb={"10px"}
            >
              {t("TEXT.PRICING.SUBTITLE")}
            </Typography>
          </Box>

          <Box
            sx={{
              paddingBottom: "20px",
              paddingTop: "10px",
              alignItems: "center",
              alignContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RatingCard />
            <Box sx={{ height: isMobile ? "15px" : "20px" }}></Box>
            <PricingToggle
              billing={billing}
              setBilling={setBilling}
              isMobile={isMobile}
            />
          </Box>
        </Box>
        {/* -------------------------- CARDS ------------------------------- */}
        <Grid container spacing={1}>
          {plansToShow.map((plan: any, index: number) =>
            isMobile ? (
              <PricingCardCompact
                index={index}
                key={index}
                plan={plan}
                billing={billing}
                onPlanSelected={onPlanSelected}
                buttonText={getPricingCardButtonText(
                  plan.plan,
                  billing,
                  subscriptionResult?.canceled || false
                )}
              />
            ) : (
              <PricingCard
                index={index}
                key={index}
                plan={plan}
                billing={billing}
                onPlanSelected={onPlanSelected}
                buttonText={getPricingCardButtonText(
                  plan.plan,
                  billing,
                  subscriptionResult?.canceled || false
                )}
              />
            )
          )}
        </Grid>
      </Box>
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={t("TEXT.PRICING.PLAN_CHANGE.TITLE")}
        message={t("TEXT.PRICING.PLAN_CHANGE.MESSAGE")}
      />
    </Box>
  );
};

const getPricingPlans = (t: TFunction) => {
  return {
    [CycleType.MONTHLY]: [
      {
        title: t("TEXT.PRICING.BASIC_NAME"),
        description: t("TEXT.PRICING.BASIC_DESCRIPTION"),
        plan: SubscriptionType.BASIC,
        photos: packagesPrices[CycleType.MONTHLY].basic.photos.toString(),
        price: `$${packagesPrices[CycleType.MONTHLY].basic.price}`,
        pricePhoto: `$${packagesPrices[CycleType.MONTHLY].basic.pricePerPhoto} / photo`,
        yearlyPrice: `$${packagesPrices[CycleType.MONTHLY].basic.yearlyDiscount} / month with yearly billing`,
        features: t("TEXT.PRICING.BASIC_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: "",
      },
      {
        title: t("TEXT.PRICING.STANDARD_NAME"),
        description: t("TEXT.PRICING.STANDARD_DESCRIPTION"),
        plan: SubscriptionType.STANDARD,
        photos: packagesPrices[CycleType.MONTHLY].standard.photos.toString(),
        price: `$${packagesPrices[CycleType.MONTHLY].standard.price}`,
        pricePhoto: `$${packagesPrices[CycleType.MONTHLY].standard.pricePerPhoto} / photo`,
        yearlyPrice: `$${packagesPrices[CycleType.MONTHLY].standard.yearlyDiscount} / month with yearly billing`,
        mostPopular: true,
        features: t("TEXT.PRICING.STANDARD_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: "",
      },
      {
        title: t("TEXT.PRICING.PROFESSIONAL_NAME"),
        description: t("TEXT.PRICING.PROFESSIONAL_DESCRIPTION"),
        plan: SubscriptionType.PROFESSIONAL,
        photos:
          packagesPrices[CycleType.MONTHLY].professional.photos.toString(),
        price: `$${packagesPrices[CycleType.MONTHLY].professional.price}`,
        pricePhoto: `$${packagesPrices[CycleType.MONTHLY].professional.pricePerPhoto} / photo`,
        yearlyPrice: `$${packagesPrices[CycleType.MONTHLY].professional.yearlyDiscount} / month with yearly billing`,
        features: t("TEXT.PRICING.PROFESSIONAL_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: "",
      },
    ],
    [CycleType.YEARLY]: [
      {
        title: t("TEXT.PRICING.BASIC_NAME"),
        description: t("TEXT.PRICING.BASIC_DESCRIPTION"),

        plan: SubscriptionType.BASIC,
        photos: `${packagesPrices[CycleType.YEARLY].basic.photos}`,
        price: `$${packagesPrices[CycleType.YEARLY].basic.price}`,
        pricePhoto: `$${packagesPrices[CycleType.YEARLY].basic.pricePerPhoto} / photo`,
        yearlyPrice: `Just $${packagesPrices[CycleType.YEARLY].basic.price}/mo with yearly billing`,
        features: t("TEXT.PRICING.BASIC_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: `Billed as $${packagesPrices[CycleType.YEARLY].basic.billedYearly} yearly`,
      },

      {
        title: t("TEXT.PRICING.STANDARD_NAME"),
        description: t("TEXT.PRICING.STANDARD_DESCRIPTION"),
        plan: SubscriptionType.STANDARD,
        photos: packagesPrices[CycleType.YEARLY].standard.photos.toString(),
        price: `$${packagesPrices[CycleType.YEARLY].standard.price}`,
        pricePhoto: `$${packagesPrices[CycleType.YEARLY].standard.pricePerPhoto} / photo`,
        yearlyPrice: `Just $${packagesPrices[CycleType.YEARLY].standard.price}/mo with yearly billing`,
        mostPopular: true,
        features: t("TEXT.PRICING.STANDARD_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: `Billed as $${packagesPrices[CycleType.YEARLY].standard.billedYearly} yearly`,
      },
      {
        title: t("TEXT.PRICING.PROFESSIONAL_NAME"),
        description: t("TEXT.PRICING.PROFESSIONAL_DESCRIPTION"),
        plan: SubscriptionType.PROFESSIONAL,
        photos: packagesPrices[CycleType.YEARLY].professional.photos.toString(),
        price: `$${packagesPrices[CycleType.YEARLY].professional.price}`,
        pricePhoto: `$${packagesPrices[CycleType.YEARLY].professional.pricePerPhoto} / photo`,
        yearlyPrice: `Just $${packagesPrices[CycleType.YEARLY].professional.price}/mo with yearly billing`,
        features: t("TEXT.PRICING.PROFESSIONAL_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: `Billed as $${packagesPrices[CycleType.YEARLY].professional.billedYearly} yearly`,
      },
    ],
  };
};
