import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

type ConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#333",
          borderRadius: "8px",
          boxShadow: 3,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "32px",
        }}
      >
        <span>{title}</span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: "#fff",
            "&:hover": {
              backgroundColor: "#444",
            },
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px 32px" }}>
        <DialogContentText sx={{ color: "#fff" }}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "32px", gap: "16px" }}>
        <Button
          onClick={onClose}
          variant="outlined"
          color="error"
          sx={{
            fontWeight: "600",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#444",
            },
          }}
        >
          {t("TEXT.CONFIRMATION_DIALOG.NO")}
        </Button>
        <Button
          onClick={onConfirm}
          variant="outlined"
          color="primary"
          autoFocus
          sx={{
            fontWeight: "600",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#444",
            },
          }}
        >
          {t("TEXT.CONFIRMATION_DIALOG.YES")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
