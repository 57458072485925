export type Review = {
  name: string;
  location: string;
  review: string;
  stars: number;
  title: string; // New property added
  company?: string;
  image?: string;
  companyLogo?: string;
};

export const reviews: Review[] = [
  {
    name: "James T.",
    location: "Miami, Florida",
    review:
      "I listed an unfurnished property and used your virtual staging. I even mentioned it was virtually staged in the listing. The photos looked amazing, and the home sold almost immediately after going live.",
    stars: 5,
    title: "Sold Instantly",
    company: "Summit Estates Realty",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fm1.png?alt=media&token=a924ad15-b425-4f11-8bed-7afa128370dc",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F3.png?alt=media&token=8e224d70-0f5d-4716-b174-9c7c5ec57a33",
  },
  {
    name: "Diane T.",
    location: "Denver, CO",
    review:
      "The virtual furniture looked so realistic, I couldn’t believe it! My clients were thrilled, and the listing got way more attention. It really helped generate interest quickly.",
    stars: 5,
    title: "So Realistic!",
    company: "BlueKey Properties",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fw3.png?alt=media&token=4fad087c-0729-42c6-bc64-a57000841704",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F4.png?alt=media&token=368adba1-219f-48bd-bb69-b2c6dbb09efc",
  },
  {
    name: "Mike S.",
    location: "San Francisco, CA",
    review:
      "The transformation was unbelievable. My empty listing turned into a designer-style home with warm, inviting decor. It attracted buyers immediately and sold within two weeks.",
    stars: 5,
    title: "Designer Look",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fm4.png?alt=media&token=957a5027-45cb-4f1a-af08-33e504e51b07",
  },
  {
    name: "David H.",
    location: "Seattle, WA",
    review:
      "The buyers were so impressed with the photos, they asked if the furniture came with the house! The staging looked that real. Highly recommend this service.",
    stars: 5,
    title: "Buyers Loved It",
    company: "Elite Home Advisors",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F1.png?alt=media&token=8e156a48-0e86-4429-bcdb-3c5bc5cfc1de",
  },
  {
    name: "Briana G.",
    location: "Atlanta, GA",
    review:
      "The staging gave my listing a fresh, modern vibe. We had barely any interest before, but after uploading the new photos, we got multiple offers in just a few days.",
    stars: 5,
    title: "From Crickets to Offers",
    company: "Elite Home Advisors",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fw1.png?alt=media&token=f5895e90-2abe-4e7e-9ede-8539076ee76a",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F5.png?alt=media&token=694ed658-68ea-44c8-a37a-a9ad03c5fff9",
  },
  {
    name: "Patricia J.",
    location: "NY, US",
    review:
      "The platform was easy to use and the results were impressive. After staging one property, I started getting showing requests almost right away. It gave my listings a real edge.",
    stars: 5,
    title: "Instant Inquiries",
    company: "Pinnacle Property Solutions",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fw2.png?alt=media&token=dcc75bd0-480a-4e90-a5e7-2d44139f2f1e",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F7.png?alt=media&token=60a488ef-3a8b-4382-9747-fff7ce5b9746",
  },
  {
    name: "Kelly P.",
    location: "Dallas, TX",
    review:
      "This saved me both time and money. No need to rent furniture, and the AI-generated results looked stunning. I’ll definitely be using this again.",
    stars: 4,
    title: "Saved Me Money",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F2.png?alt=media&token=64f8dbc2-b8ad-4965-bd78-4aea375bf768",
  },
  {
    name: "Laura D.",
    location: "Orlando, FL",
    review:
      "The furniture and decor matched the space so well. The photos looked like they were from a luxury magazine. We had showings lined up within days and buyers loved it.",
    stars: 5,
    title: "Dream Home Vibes",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F6.png?alt=media&token=33b3d5de-b469-4294-865c-0b464b4b8808",
  },
  {
    name: "Michael S.",
    location: "Portland, OR",
    review:
      "I needed staging on a tight deadline and was blown away. The quality was top-notch, and the home looked stunning. We had buyers reaching out the same day it was listed.",
    stars: 5,
    title: "Deadline Winner",
    company: "Horizon Realty Group",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fm6.png?alt=media&token=26a60240-e635-47af-9777-97b6f62bac66",
    companyLogo:
      "https://logowik.com/content/uploads/images/361_realestate_logo.jpg",
  },
];
