import React from "react";
import { Box, Typography, IconButton, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ImageIcon from "@mui/icons-material/Image";
import { customColors } from "../theme";
import { useTranslation } from "react-i18next";
import IconTitle from "./IconTitle";
import {
  AutoAwesome,
  DownloadDoneOutlined,
  UploadFileOutlined,
} from "@mui/icons-material";

export const HowItWorks: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  type Step = {
    title: string;
    description: string;
    icon: React.JSX.Element;
    image: string;
  };
  const steps: Step[] = [
    {
      title: t("TEXT.HOW_IT_WORKS.STEP_1_TITLE"),
      description: t("TEXT.HOW_IT_WORKS.STEP_1_DESCRIPTION"),
      icon: <UploadFileOutlined />,
      image:
        "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/HomePAgeSteps%2FstepI.jpg?alt=media&token=a7c06676-a85c-4fdb-9e71-d10af334eda0",
    },
    {
      title: t("TEXT.HOW_IT_WORKS.STEP_2_TITLE"),
      description: t("TEXT.HOW_IT_WORKS.STEP_2_DESCRIPTION"),
      icon: <AutoAwesome />,
      image:
        "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/HomePAgeSteps%2FstepII.jpg?alt=media&token=7685742b-ce6d-4cde-b252-6718101e9614",
    },
    {
      title: t("TEXT.HOW_IT_WORKS.STEP_3_TITLE"),
      description: t("TEXT.HOW_IT_WORKS.STEP_3_DESCRIPTION"),
      icon: <DownloadDoneOutlined />,
      image:
        "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/HomePAgeSteps%2FstepIII.jpg?alt=media&token=ff196721-90b8-46f3-b363-43678b40fbf8",
    },
  ];

  return (
    <Box
      sx={{
        padding: { xs: "10px 15px", md: "40px 20px" },
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Grid
        sx={{
          gap: 0,
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconTitle
              title={t("TEXT.HOW_IT_WORKS.ICON_TITLE")}
              centered
              icon={
                <IconButton>
                  <ImageIcon
                    fontSize={"medium"}
                    sx={{ color: customColors.gold }}
                  />
                </IconButton>
              }
            />
          </div>
          <Typography
            variant={isMobile ? "h6" : "h4"}
            component="div"
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            <b>{t("TEXT.HOW_IT_WORKS.TITLE")}</b>{" "}
            {t("TEXT.HOW_IT_WORKS.TITLE2")}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }} textAlign={"center"}>
            {t("TEXT.HOW_IT_WORKS.SUB_TITLE")}
          </Typography>
        </Box>

        <Grid
          container
          wrap={isMobile ? "wrap" : "nowrap"}
          spacing={isMobile ? "30px" : "50px"}
        >
          {steps.map((step, index) => (
            <Grid
              key={step.title}
              size={{ xs: 12, md: 5 }}
              sx={{
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                flexGrow: 1,
                maxWidth: isMobile ? "800px" : "40%",
                backgroundColor: customColors.mediumGray,
                padding: isMobile ? "20px 10px" : "20px",
                marginTop: "40px",
                borderRadius: "10px",
                transition: isMobile
                  ? ""
                  : "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": isMobile
                  ? {}
                  : {
                      transform: "scale(1.1)",
                      boxShadow: "0 6px 20px rgba(0, 0, 0, 0.15)",
                    },
              }}
            >
              {/* Number badge */}
              <Typography
                fontWeight={"900"}
                variant="h1"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  transform: "translate(-30%, -30%)",

                  color: "white",
                  width: "64px",
                  height: "64px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // fontSize: "16px",
                  zIndex: 2,
                }}
              >
                {index + 1}
              </Typography>

              <Box>
                <img
                  src={step.image}
                  width={isMobile ? "60%" : "100%"}
                  style={{ borderRadius: "10px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  {/* <Typography
                    fontSize={16}
                    color={customColors.darkGray}
                    sx={{
                      backgroundImage: goldGradient,
                      fontWeight: "700",
                      padding: "0px 8px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  >
                    {index + 1}
                  </Typography> */}

                  <Typography variant={isMobile ? "h6" : "h5"} component="div">
                    <b>{step.title}</b>
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    mb: isMobile ? 1 : 3,
                    mt: 2,
                  }}
                  component={"div"}
                >
                  {step.description.split("\n").map((text, index) => (
                    <Typography key={index} fontSize={isMobile ? 14 : 16}>
                      {text}
                      <br />
                    </Typography>
                  ))}
                </Typography>

                {/* <img
                  src={step.image}
                  width={isMobile ? "60%" : "90%"}
                  style={{ borderRadius: "10px" }}
                /> */}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
