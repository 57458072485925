import { useMutation } from "@tanstack/react-query";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../context/AuthContext";
import {
  createPayment,
  PaymentParams,
} from "../../api/services/paymentService";
import { useState } from "react";

export const useCreatePaymentMutation = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { showSnackbar } = useSnackbarContext();

  const [createPaymentRedirectURL, setCreatePaymentRedirectURL] = useState<
    string | null
  >(null);

  const mutation = useMutation<string, Error, PaymentParams>({
    mutationFn: async ({ plan, cycle }: PaymentParams) => {
      if (!token) throw new Error("Missing JWT token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await createPayment({ plan, cycle }, headers);
      return response.redirect_uri;
    },
    onSuccess: (redirectURL) => {
      setCreatePaymentRedirectURL(redirectURL);
    },
  });

  return {
    createPaymentRedirectURL,
    handleCreatePayment: mutation.mutate,
    isCreatePaymentLoading: mutation.isPending,
    isCreatePaymentError: mutation.isError,
    isCreatePaymentSuccess: mutation.isSuccess,
  };
};
