import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "../../context/AuthContext";
import {
  getGallery,
  GetGalleryParams,
  GetGalleryResponse,
} from "../../api/services/galleryService";

const DEFAULT_GALLERY: GetGalleryResponse = {
  orders: [],
  total_count: 0,
};

export const useGallery = ({ page, itemsPerPage }: GetGalleryParams) => {
  const { token } = useAuthContext();
  const {
    data: gallery = DEFAULT_GALLERY,
    isLoading: isGalleryLoading,
    isError: isGalleryError,
  } = useQuery({
    queryKey: ["getGallery", page, itemsPerPage],
    queryFn: async () => {
      if (!token) throw new Error("Missing JWT token");

      const headers = { Authorization: `Bearer ${token}` };
      return getGallery({ page, itemsPerPage }, headers);
    },
    enabled: !!token,
  });

  return {
    gallery,
    isGalleryLoading,
    isGalleryError,
  };
};
