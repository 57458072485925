import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  InputLabel,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { customColors } from "../../theme";
import { CustomButton } from "../CustomButton";
import { HelpOutline } from "@mui/icons-material";
import { OrderSetup } from "./types";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader";
import { useSubscriptionContext } from "../../context/SubscriptionContext";
import { useSpacesQuery } from "../../hooks/queries/useSpacesQuery";
import { trackOrderCreationSettingsEvent } from "../../utils/tracking";
import { BaseSelect } from "../Filters/BaseSelect";
import { Space, Style } from "../../api/services/spaceService";
import { useAuthContext } from "../../context/AuthContext";

interface Props {
  handleCreateOrder: (
    removeFurniture: boolean,
    spaceName: string,
    styleId: string,
    widgetId?: string
  ) => void;
  isDisabled: boolean;
  setIsError: (value: React.SetStateAction<boolean>) => void;
  actionText?: string;
  isLoading?: boolean;
  isGenerateMore?: boolean;
}

// TODO unite old and new component after BE sync
export const FormPanel: React.FC<Props> = ({
  handleCreateOrder,
  isDisabled,
  setIsError,
  actionText,
  isLoading,
  isGenerateMore = false,
}) => {
  const [orderSetup, setOrderSetup] = useState<OrderSetup>({
    photo: null,
    roomType: "",
    furnitureStyle: "",
    removeFurniture: true,
  });
  const { spaces, isSpacesLoading, isSpacesError } = useSpacesQuery();
  const { isUserVerified } = useAuthContext();

  const navigate = useNavigate();

  const [availableStyles, setAvailableStyles] = useState<Style[]>([]);

  const { isSubscriptionLoading, subscriptionResult } =
    useSubscriptionContext();

  const notEnoughCredits: boolean = isGenerateMore
    ? false
    : !isSubscriptionLoading &&
      subscriptionResult !== undefined &&
      subscriptionResult.plan !== 0 &&
      subscriptionResult?.credits_amount === 0;

  useEffect(() => {
    if (isSpacesError) {
      setIsError(true);
      trackOrderCreationSettingsEvent({ event: "Spaces API error" });
    }
    if (spaces.length > 0) {
      const firstRoom = spaces[0];
      setAvailableStyles(firstRoom.styles || []);

      setOrderSetup((prevState) => ({
        ...prevState,
        furnitureStyle:
          prevState.furnitureStyle ||
          (firstRoom.styles.length > 0 ? firstRoom.styles[0].id : ""),
        roomType: prevState.roomType || firstRoom.name,
      }));
    }
  }, [isSpacesError, spaces, setIsError]);

  const handleProcessPhoto = () => {
    const widget = (
      spaces.find((space: Space) => space.name === orderSetup.roomType)
        ?.styles || []
    ).find((styl: Style) => styl.id === orderSetup.furnitureStyle)?.widget_id;
    if (!isDisabled) {
      handleCreateOrder(
        orderSetup.removeFurniture,
        orderSetup.roomType,
        orderSetup.furnitureStyle,
        widget || "65b4e95ec2b80c47dfe933ab"
      );
    }
  };

  const { t } = useTranslation();

  const handleRoomTypeChange = (roomTypeName: string) => {
    trackOrderCreationSettingsEvent({
      event: "Room type change",
      label: roomTypeName,
    });
    const selectedSpace = spaces.find(
      (space: Space) => space.name === roomTypeName
    );
    let selectedStyle = "";
    if (selectedSpace) {
      setAvailableStyles(selectedSpace.styles);
      if (selectedSpace.styles && selectedSpace.styles.length > 0) {
        selectedStyle = selectedSpace.styles[0].id;
      } else {
        selectedStyle = "";
      }
    }

    setOrderSetup({
      photo: orderSetup.photo,
      furnitureStyle: selectedStyle,
      removeFurniture: orderSetup.removeFurniture,
      roomType: roomTypeName,
    });
  };

  const furnitureRemovalField = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "10px",
      }}
    >
      <Checkbox
        checked={orderSetup.removeFurniture}
        onChange={(e) => {
          setOrderSetup({
            ...orderSetup,
            removeFurniture: e.target.checked,
          });
          trackOrderCreationSettingsEvent({
            event: "Furniture removal checkbox changed",
            label: e.target.checked ? "Checked" : "Unchecked",
          });
        }}
        sx={{ color: "#FFF" }}
      />
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        {t("TEXT.UPLOAD_PHOTO.REMOVE_FURNITURE")}
      </Typography>
      <Tooltip title={t("TEXT.UPLOAD_PHOTO.REMOVE_FURNITURE_INFO")} arrow>
        <IconButton size="small" sx={{ ml: 1 }}>
          <HelpOutline fontSize="small" sx={{ color: "#FFF" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const spaceStyleSelection = (
    <Box
      sx={{
        mb: 2,
        mt: 4,
        border: "1px solid #ccc",
        borderRadius: "8px",
        p: "15px",
        color: "#fff",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 600, color: "#fff", mb: "10px" }}
      >
        {t("TEXT.UPLOAD_PHOTO.ADD_FURNITURE")}
      </Typography>

      <InputLabel id="room-type-label" sx={{ color: "#fff" }} shrink>
        {t("TEXT.UPLOAD_PHOTO.ROOM_TYPE")}
      </InputLabel>
      <BaseSelect
        value={orderSetup.roomType}
        onChange={(e) => handleRoomTypeChange(e.target.value)}
        menuItems={spaces.map((space) => ({
          label: space.title,
          value: space.name,
          icon: space.icon_src,
          locked: isUserVerified
            ? false
            : space.name !== "space-bedroom" &&
              space.name !== "space-living-room", // lock most room types for unverified users
        }))}
        disabled={spaces.length === 0}
      />

      <InputLabel
        id="furniture-style-label"
        sx={{ color: "#fff", mt: "10px" }}
        shrink
      >
        {t("TEXT.UPLOAD_PHOTO.STYLE")}
      </InputLabel>
      <BaseSelect
        value={orderSetup.furnitureStyle}
        onChange={(e) => {
          setOrderSetup({ ...orderSetup, furnitureStyle: e.target.value });
          trackOrderCreationSettingsEvent({
            label: "Furniture style change",
            event: e.target.value,
          });
        }}
        menuItems={availableStyles.map((style) => ({
          label: style.title,
          value: style.id,
        }))}
        disabled={availableStyles.length === 0}
      />
    </Box>
  );

  const outOfPhotos = (
    <Box sx={{ p: "10px 20px" }}>
      <Typography
        variant="subtitle1"
        sx={{ color: "#fff", mb: "10px", textAlign: "center" }}
      >
        {t("TEXT.UPLOAD_PHOTO.NOT_ENOUGH_CREDITS")}
      </Typography>
      <CustomButton
        text={t("TEXT.UPLOAD_PHOTO.NOT_ENOUGH_CREDITS_BUTTON")}
        onClick={() => navigate("/pricing")}
      />
    </Box>
  );

  return notEnoughCredits ? (
    outOfPhotos
  ) : (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {isGenerateMore ? (
        <></>
      ) : (
        <>
          {isSpacesLoading || isSubscriptionLoading ? (
            <Loader maxHeigth="250px" maxHeigthMobile="250px" />
          ) : (
            <Box>
              {furnitureRemovalField}
              {spaceStyleSelection}
            </Box>
          )}
        </>
      )}

      <Box mt={1}>
        <CustomButton
          text={actionText || t("TEXT.UPLOAD_PHOTO.PROCESS_PHOTO")}
          onClick={handleProcessPhoto}
          isDisabled={isDisabled}
          isLoading={isLoading || false}
        />
      </Box>

      <Typography
        sx={{
          color: customColors.silver,
          fontSize: "13px",
          textAlign: "center",
          mb: 2,
        }}
      >
        {"Unlimited variations and downloads included"}
      </Typography>
    </Box>
  );
};
