import React from "react";
import { Done } from "@mui/icons-material";
import { Box, Typography, Button, Card, CardContent } from "@mui/material";
import { customColors, goldGradientCentral } from "../../theme";
import { t } from "i18next";
import Grid from "@mui/material/Grid2";
import {
  CycleType,
  SubscriptionType,
} from "../../api/services/userSubscriptionService";

export type PricingPlan = {
  title: string;
  description: string;
  plan: SubscriptionType;
  photos: string;
  price: string;
  pricePhoto: string;
  yearlyPrice: string;
  features: string[];
  billedYearly: string;
  mostPopular?: boolean;
};

interface PricingCardsProps {
  index: number;
  plan: PricingPlan;
  billing: CycleType;
  onPlanSelected: (billing: CycleType, plan: SubscriptionType) => void;
  buttonText: string;
}

export const PricingCard: React.FC<PricingCardsProps> = ({
  plan,
  index,
  billing,
  onPlanSelected,
  buttonText,
}) => {
  return (
    <Grid size={{ xs: 12, md: 3.9 }} key={index}>
      <Box
        onClick={() => onPlanSelected(billing, plan.plan)}
        sx={{
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.02)",
            borderColor: customColors.gold,
          },
        }}
      >
        <Card
          sx={{
            padding: { xs: "8px 8px 0 8px", md: "1rem 1rem 0 1rem" },
            position: "relative",
            borderRadius: "26px",
            minHeight: { xs: "200px", md: "600px" },
            backgroundColor: customColors.darkGray,
            border: plan.mostPopular
              ? "1px solid " + customColors.gold
              : "2px solid " + customColors.mediumGray,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <CardContent
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                border:
                  "1px solid " +
                  (plan.mostPopular ? customColors.white : customColors.white),
                display: "inline-block",
                borderRadius: "20px",
                padding: "6px 12px",
                color: customColors.white,
                boxShadow: "0 0px 6px 4px rgba(235, 235, 235, 0.2)",
                fontSize: "12px",
                textAlign: "center",
                letterSpacing: "3px",
                fontWeight: 900,
              }}
            >
              {plan.title.toUpperCase()}
            </Typography>

            <Typography
              variant={"h1"}
              sx={{
                mt: 2,
                textAlign: "center",
                color: customColors.gold,
              }}
            >
              <b>{plan.photos}</b>
            </Typography>
            <Typography
              sx={{
                mt: "-10px",
                mb: 1,
                textAlign: "center",
                color: customColors.gold,
                fontSize: "17px",
                fontWeight: 600,
              }}
            >
              {t("TEXT.PRICING.PHOTOS_PER_MONTH")}
            </Typography>

            <Typography
              variant={"body1"}
              color="white"
              sx={{
                mb: 1,
                textAlign: "center",
              }}
            >
              {plan.description}
            </Typography>

            <Typography
              variant="h2"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: plan.mostPopular ? customColors.gold : "white",
              }}
            >
              <span style={{ fontSize: "36px" }}>{plan.price}</span>
              <span
                style={{
                  fontSize: "24px",
                  marginLeft: "4px",
                  position: "relative",
                  bottom: "-4px",
                }}
              >
                {t("TEXT.PRICING.PER_MONTH")}
              </span>
            </Typography>

            <Typography
              variant="body1"
              color="white"
              sx={{
                mb: 2,
                textAlign: "center",
              }}
            >
              {plan.pricePhoto}
            </Typography>

            {billing === CycleType.YEARLY ? null : (
              <Box
                sx={{
                  backgroundColor: "rgba(235, 235, 225, 0.1)",
                  borderRadius: "16px",
                  mb: "2px",
                  padding: "5px 12px",
                  color: customColors.gold,
                  display: "inline-block",
                  fontWeight: "400",
                  fontSize: "14px",
                  visibility:
                    billing === CycleType.MONTHLY ? "visible" : "hidden",
                  textAlign: "center",
                }}
              >
                {plan.yearlyPrice}
              </Box>
            )}

            <Box sx={{ mt: "20px" }}>
              {plan.features.map((feature, i) => (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "left",
                    justifyContent: "left",
                  }}
                >
                  <Done fontSize="small" />
                  <Typography
                    variant="body2"
                    sx={{ mb: 1, ml: "8px", textAlign: "left" }}
                  >
                    {feature}
                  </Typography>
                </Box>
              ))}
            </Box>
          </CardContent>

          <Button
            variant="contained"
            color="primary"
            onClick={() => onPlanSelected(billing, plan.plan)}
            sx={{
              mt: { xs: 0, md: 2 },
              mb: { xs: 0, md: 3 },
              minWidth: { xs: "100px", md: "100%" },
              borderRadius: "12px",
              textAlign: "center",
              backgroundImage: goldGradientCentral,
              fontSize: "14px",
            }}
          >
            <b>{buttonText}</b>
          </Button>

          {billing === CycleType.YEARLY && (
            <Typography
              fontSize={"16px"}
              align="center"
              color={customColors.grayedGold}
              sx={{ mt: "0", mb: 2, textAlign: "center" }}
            >
              {plan.billedYearly}
            </Typography>
          )}
        </Card>
      </Box>
    </Grid>
  );
};
