import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ImageComparison from "../../Gallery/ImageComparison";
import {
  ArrowBackIos,
  FileUpload,
  InsertPhotoOutlined,
} from "@mui/icons-material";
import { customColors } from "../../../theme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSubscriptionContext } from "../../../context/SubscriptionContext";
import { ProcessPhotoComponent } from "../../../components/Photos/ProcessPhotoComponent";
import { SubscriptionType } from "../../../api/services/userSubscriptionService";
import { useAuthContext } from "../../../context/AuthContext";
import { Loader } from "../../../components/Loader";
import { trackButtonClickEvent } from "../../../utils/tracking";
import {
  useFeatureFlagPayload,
  usePostHog,
  useFeatureFlagVariantKey,
} from "posthog-js/react";
import { POSTHOC_KEYS } from "../../../constants";

export const HeroSection: React.FC = () => {
  const { t } = useTranslation();
  const posthog = usePostHog();

  const [isEntryState, setIsEntryState] = useState<boolean>(true);
  const navigate = useNavigate();

  // AB split test - button text
  const variantKey = useFeatureFlagVariantKey(POSTHOC_KEYS.heroSectionButton);
  const splitTestPayload = useFeatureFlagPayload(
    POSTHOC_KEYS.heroSectionButton
  );
  const testButtonText = (splitTestPayload as { value?: string })?.value;

  const { isUserVerified } = useAuthContext();
  const { subscriptionResult, isSubscriptionLoading } =
    useSubscriptionContext();

  const { plan, canceled } = subscriptionResult || {};
  const isNewUser =
    (isUserVerified && plan === SubscriptionType.FREE && !canceled) ||
    (isUserVerified && !subscriptionResult);

  const isExistingUser =
    (isUserVerified && plan !== SubscriptionType.FREE) ||
    (isUserVerified && canceled);

  const beforeImg = new Image();
  const afterImg = new Image();
  beforeImg.src =
    "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F90.jpg?alt=media&token=dee8cbe4-0b64-4891-9252-c9aef9b7da96";
  afterImg.src =
    "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F92.jpg?alt=media&token=cd29aa38-efbe-4711-a422-177fb403eb1b";

  const isMobile = useMediaQuery("(max-width: 768px)");
  const image = (
    <Grid
      sx={{
        gridColumn: { xs: "1 / -1", md: "2/5" }, // Full width on mobile, 8 columns for image section
      }}
    >
      <ImageComparison beforeImage={beforeImg} afterImage={afterImg} />
    </Grid>
  );

  const text = (
    <Grid
      sx={{
        gridColumn: { xs: "1 / -1", md: "1 / 2" }, // Full width on mobile, 4 columns on medium and up
        marginRight: "24px",
        width: "100%",
        paddingRight: { xs: "0px", md: "1px" },
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(4, 218, 141, 0.15)",
          borderRadius: "20px",
          p: "10px 20px",
          marginBottom: "30px",
          width: "fit-content", // Adjust width to text size
          display: "inline-block",
        }}
      >
        <Typography
          fontSize={"14px"}
          sx={{
            color: "#05faa0",
            fontWeight: "600",
          }}
        >
          <span
            style={{
              fontWeight: "600",
            }}
          >
            {"Free trial."}
          </span>

          <span
            style={{
              fontWeight: "500",
            }}
          >
            {" Pricing from"}
          </span>
          <span
            style={{
              fontWeight: "600",
            }}
          >
            {" $0.4 "}
          </span>

          <span
            style={{
              fontWeight: "500",
            }}
          >
            {" per picture"}
          </span>
        </Typography>
      </Box>
      <Typography variant={isMobile ? "h5" : "h2"} gutterBottom>
        <span style={{ fontWeight: "bold", color: customColors.gold }}>
          {t("TITLE.AI")}{" "}
        </span>
        <span style={{ fontWeight: "bold" }}>{t("TITLE.MAIN")}</span>
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {t("TEXT.UPLOAD_PROMPT")}
      </Typography>
      <Box
        sx={{
          visibility: isSubscriptionLoading ? "hidden" : "visible",
          height: isSubscriptionLoading ? 0 : "60px",
          opacity: isSubscriptionLoading ? 0 : 1,
          transition: "height 0.3s ease, opacity 0.3s ease",
        }}
      >
        {isSubscriptionLoading ? (
          <Loader />
        ) : isNewUser ? (
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            style={{ borderRadius: "15px", padding: "10px", width: "100%" }}
            onClick={() => {
              trackButtonClickEvent({
                label: "Subscribe Now",
                extraParams: { placement: "Main page Hero section" },
              });
              navigate("/pricing");
            }}
          >
            <strong>{t("TEXT.SUBSCRIBE_NOW")}</strong>
          </Button>
        ) : isExistingUser ? (
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            style={{ borderRadius: "15px", padding: "10px", width: "100%" }}
            onClick={() => {
              trackButtonClickEvent({
                label: "My photos",
                extraParams: { placement: "Main page Hero section" },
              });
              navigate("/myphotos");
            }}
            startIcon={<InsertPhotoOutlined />}
          >
            <strong>{t("BUTTONS.GO_TO_PHOTOS")}</strong>
          </Button>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignContent: isMobile ? "center" : "left",
                alignItems: isMobile ? "center" : "left",
              }}
            >
              <Button
                variant="contained"
                endIcon={<FileUpload />}
                sx={{ mt: 2 }}
                style={{ borderRadius: "15px", padding: "10px", width: "90%" }}
                onClick={() => {
                  trackButtonClickEvent({
                    label: "Free photo upload",
                    extraParams: { placement: "Main page Hero section" },
                  });
                  posthog?.capture("hero_button_clicked", {
                    variant: testButtonText,
                    environment: process.env.NODE_ENV || "development",
                    feature_flag_variant: variantKey,
                  });
                  setIsEntryState(false);
                }}
              >
                <strong>{testButtonText || t("BUTTONS.UPLOAD_IMAGE")}</strong>
              </Button>
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  width: "90%",
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {t("TEXT.NO_SIGNUP")}
              </Typography>{" "}
            </Box>
          </>
        )}
      </Box>
    </Grid>
  );

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        color: customColors.white,
        padding: { xs: "0px", md: "0px" },
        alignItems: "center",
        alignContent: "center",
      }}
    >
      {isEntryState ? (
        <Container
          sx={{
            // padding: { xs: "0px", md: "30px" },
            marginTop: { xs: "45px", md: "60px" },
            marginBottom: { xs: "0px", md: "30px" },
          }}
        >
          <Grid
            container
            columns={12} // Define the number of columns explicitly
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "3fr 4fr" }, // 4 columns for text, 8 for the image on medium and up
              gap: 3, // spacing between grid items
              alignItems: "center",
            }}
          >
            {isMobile ? (
              <>
                {image}
                {text}
              </>
            ) : (
              <>
                {text}
                {image}
              </>
            )}
          </Grid>
        </Container>
      ) : (
        <Box sx={{ maxWidth: "1200px", margin: "0 auto" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              trackButtonClickEvent({
                label: "Back from free photo upload",
                extraParams: { placement: "Main page Hero section" },
              });

              setIsEntryState(true);
            }}
            sx={{ margin: "8px" }}
          >
            <ArrowBackIos fontSize="small" />
            <Typography fontSize={"16px"}>{t("TEXT.BACK")}</Typography>
          </IconButton>
          <ProcessPhotoComponent />
        </Box>
      )}
    </Box>
  );
};
