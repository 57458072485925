import styled from "@emotion/styled";
import {
  Card,
  Box,
  Typography,
  Divider,
  Button,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { customColors } from "../../theme";
import {
  AttachMoneyOutlined,
  CancelOutlined,
  UpgradeSharp,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader } from "../Loader";
import { ErrorComponent } from "../ErrorComponent";
import { SubscriptionType } from "../../api/services/userSubscriptionService";
import { usePaymentHistory } from "../../hooks/usePaymentHistory";
import { PaymentHistory } from "./PaymentHistory";
import { useSubscriptionContext } from "../../context/SubscriptionContext";
import { useCancelPaymentMutation } from "../../hooks/mutations/useCancelPaymentMutation";
import { useManagePayment } from "../../hooks/queries/useManagePayment";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { ConfirmationDialog } from "../ConfirmationDialog";

const BillingCard = styled(Card)(({ theme }) => ({
  padding: "20px",
  borderRadius: "12px",
  marginTop: "20px",
  backgroundColor: customColors.semiDarkGray,
  color: "#fff",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
  border: "1px solid " + customColors.lightGray,
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  marginBottom: "10px",
}));

export const Settings: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbarContext();
  const {
    managePaymentRedirectURL,
    fetchManagePayment,
    isManagePaymentLoading,
    isManagePaymentError,
  } = useManagePayment();
  const { handleCancelPayment, isCancelPaymentLoading } =
    useCancelPaymentMutation();
  const { subscriptionResult, isSubscriptionLoading, isSubscriptionError } =
    useSubscriptionContext();
  const { paymentHistoryResult } = usePaymentHistory();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const {
    plan,
    cost,
    next_payment_date,
    used_credits_per_month,
    total_credits_per_month,
    canceled,
    cancelation_date,
  } = subscriptionResult || {};

  useEffect(() => {
    if (isManagePaymentError) {
      showSnackbar({
        message: t("TEXT.PAYMENT.ERRORS.MANAGE_PAYMENT_FAILED"),
        color: "error",
      });
    }
    if (managePaymentRedirectURL) {
      window.location.href = managePaymentRedirectURL;
    }
  }, [isManagePaymentError, managePaymentRedirectURL, showSnackbar, t]);

  const handleCancelSubscription = async () => {
    setOpenCancelDialog(false);
    handleCancelPayment();
  };

  const isFreePlan = plan === SubscriptionType.FREE;
  const shouldHideNextPayment: boolean =
    canceled !== undefined &&
    canceled === true &&
    next_payment_date !== undefined &&
    cancelation_date !== undefined &&
    !dayjs(next_payment_date)
      .startOf("day")
      .isBefore(dayjs(cancelation_date).startOf("day"));

  const formattedPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(cost || 0); // TODO remove !canceled once BE fixes next_payment_date

  return isSubscriptionLoading ||
    isCancelPaymentLoading ||
    isManagePaymentLoading ||
    managePaymentRedirectURL ? (
    <Loader />
  ) : isSubscriptionError ? (
    <ErrorComponent />
  ) : (
    <Box p={4} maxWidth={1400} mx="auto">
      <Typography variant="h4" gutterBottom>
        {t("TEXT.SETTINGS.TITLE")}
      </Typography>

      <BillingCard>
        <Typography variant="h6">{t("TEXT.SETTINGS.BILLING_TITLE")}</Typography>
        <Typography variant="body2" gutterBottom>
          {t("TEXT.SETTINGS.BILLING_DESCRIPTION")}
        </Typography>

        <Divider sx={{ marginY: 2, backgroundColor: customColors.lightGray }} />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
        >
          {next_payment_date && !isFreePlan && !shouldHideNextPayment ? (
            <Box display="flex" flexDirection="column">
              <Typography variant="body1">
                {t("TEXT.SETTINGS.NEXT_PAYMENT")}{" "}
                <strong>{formattedPrice}</strong>
              </Typography>

              <Typography variant="body1">
                {t("TEXT.SETTINGS.NEXT_PAYMENT_DATE")}{" "}
                <strong>
                  {dayjs(next_payment_date).format("MMM D, YYYY")}
                </strong>
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          {!isFreePlan || canceled ? (
            <Button
              variant="outlined"
              startIcon={<AttachMoneyOutlined />}
              onClick={() => fetchManagePayment()}
            >
              {t("TEXT.SETTINGS.MANAGE_PAYMENT")}
            </Button>
          ) : null}
        </Box>
      </BillingCard>

      <BillingCard>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          sx={{ marginBottom: "10px" }}
        >
          <Box display="flex" pr="8px" gap="8px" flexWrap="wrap" rowGap="8px">
            <Typography variant="h6" sx={{ marginRight: "10px" }}>
              {t("TEXT.SETTINGS.PLAN." + plan)} {t("TEXT.SETTINGS.PLAN_TITLE")}
            </Typography>
            {canceled ? (
              <Button
                variant="outlined"
                color="error"
                sx={{
                  pointerEvents: "none",
                }}
              >
                {t("TEXT.SETTINGS.CANCELLED")}
              </Button>
            ) : null}
          </Box>

          <Box
            flexDirection={{ xs: "column", sm: "row" }}
            display="flex"
            flexWrap="wrap"
            gap="8px"
            rowGap="8px"
          >
            {!isFreePlan && !canceled ? (
              <Button
                variant="outlined"
                color="error"
                startIcon={<CancelOutlined />}
                onClick={() => setOpenCancelDialog(true)}
              >
                {t("TEXT.SETTINGS.CANCEL")}
              </Button>
            ) : null}
            <Button
              variant="outlined"
              onClick={() => navigate(`/pricing`)}
              startIcon={<UpgradeSharp />}
            >
              {canceled
                ? t("TEXT.SETTINGS.RENEW")
                : isFreePlan
                  ? t("TEXT.SETTINGS.SUBSCRIBE_NOW")
                  : t("TEXT.SETTINGS.UPGRADE")}
            </Button>
          </Box>
        </Box>
        {canceled && (
          <Typography variant="body2">
            {"Your subscription will cancel on"}{" "}
            {dayjs(cancelation_date).format("MMMM D, YYYY")}
          </Typography>
        )}

        {isFreePlan ? null : (
          <>
            <Divider
              sx={{ marginY: 2, backgroundColor: customColors.lightGray }}
            />

            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  📸
                </Box>
                {t("TEXT.SETTINGS.PHOTO_LIMIT", {
                  total: total_credits_per_month,
                })}
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  🎨
                </Box>
                {t("TEXT.SETTINGS.ALL_ROOM_TYPES")}
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  🪑
                </Box>
                {t("TEXT.SETTINGS.FURNITURE_REMOVAL")}
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  🚀
                </Box>
                {t("TEXT.SETTINGS.UNLIMITED_RENDERS")}
              </Typography>

              <Typography variant="body2" display="flex" alignItems="center">
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  ❌
                </Box>
                {t("TEXT.SETTINGS.NO_WATERMARKS")}
              </Typography>
            </Box>

            <>
              <Divider
                sx={{ marginY: 2, backgroundColor: customColors.lightGray }}
              />

              <ProgressWrapper>
                <Typography variant="body2">
                  {t("TEXT.SETTINGS.USED_PHOTOS", {
                    used: used_credits_per_month,
                    total: total_credits_per_month,
                  })}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    ((used_credits_per_month ?? 0) /
                      (total_credits_per_month ?? 1)) *
                    100
                  }
                  sx={{
                    height: 16,
                    borderRadius: 5,
                    mt: "20px",
                    backgroundColor: customColors.lightGray,
                  }}
                />
              </ProgressWrapper>

              {!next_payment_date || canceled ? null : (
                <Typography variant="body2" align="right">
                  {t("TEXT.SETTINGS.IMAGE_CREDITS_REFILL", {
                    date: dayjs(next_payment_date).format("MMMM D, YYYY"),
                  })}
                </Typography>
              )}
            </>
          </>
        )}
      </BillingCard>
      <ConfirmationDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onConfirm={handleCancelSubscription}
        title={t("TEXT.SETTINGS.CONFIRM_CANCEL_TITLE")}
        message={t("TEXT.SETTINGS.CONFIRM_CANCEL_MESSAGE")}
      />
      <BillingCard>
        <PaymentHistory payments={paymentHistoryResult} />
      </BillingCard>
    </Box>
  );
};
