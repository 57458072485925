import React from "react";
import { Box, Typography } from "@mui/material";

import { Trans, useTranslation } from "react-i18next";

const RatingCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      p={2}
      borderRadius={2}
      flexDirection={"column"}
      width={"200px"}
      alignContent={"center"}
    >
      <Box
        component="img"
        src="https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/stars-5.svg?alt=media&token=6e3a1a1e-66bd-41a0-a3a5-1aeb068be4a8"
        alt="Trustpilot Rating Stars"
        height={20}
      />

      <Typography fontSize="12px" color="white" fontWeight={550} mt="4px">
        <Trans
          components={{
            bold: (
              <Typography component="span" fontWeight={650} fontSize="14px" />
            ),
          }}
        >
          {t("TEXT.RATING_TEXT")}
        </Trans>
      </Typography>
    </Box>
  );
};

export default RatingCard;
