import { ENDPOINTS } from "../endpoints";

export type RoomProperties = {
  room_types: Array<{ id: string; name: string }>;
  room_styles: Array<{ id: string; name: string }>;
};

export const getRoomProperties = async (): Promise<RoomProperties> => {
  try {
    const response = await fetch(ENDPOINTS.ROOM_PROPERTIES, {
      method: "GET",
      headers: { Accept: "application/json" },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch room properties");
    }

    const data = (await response.json()) as RoomProperties;
    return data;
  } catch (error) {
    console.error("Error fetching room properties:", error);
    throw error;
  }
};
