import { CycleType } from "../../api/services/userSubscriptionService";

export const packagesPrices = {
  [CycleType.MONTHLY]: {
    basic: {
      photos: 6,
      price: 15,
      pricePerPhoto: 2.5,
      yearlyDiscount: 7.5,
      billedYearly: null,
    },
    standard: {
      photos: 20,
      price: 25,
      pricePerPhoto: 1.25,
      yearlyDiscount: 12.5,
      billedYearly: null,
    },
    professional: {
      photos: 60,
      price: 49,
      pricePerPhoto: 0.81,
      yearlyDiscount: 24.5,
      billedYearly: null,
    },
  },
  [CycleType.YEARLY]: {
    basic: {
      photos: 6,
      price: 7.5,
      pricePerPhoto: 1.25,
      billedYearly: 90,
    },
    standard: {
      photos: 20,
      price: 12.5,
      pricePerPhoto: 0.62,
      billedYearly: 150,
    },
    professional: {
      photos: 60,
      price: 24.5,
      pricePerPhoto: 0.4,
      billedYearly: 294,
    },
  },
};
