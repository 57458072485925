import { ENDPOINTS } from "../endpoints";

interface OrderParams {
  orderId: string;
}

export enum AiVersion {
  NEW = 2,
  OLD = 1,
}

export type GetOrderResultResponse = {
  before_image_src: string;
  furniture_removed: boolean;
  image: ImageResponse[];
  ai_version: AiVersion;
};

export type ImageResponse = {
  id: string;
  image_src: string;
  space_name: string;
  style_name: string;
};

// Adjusted to accept headers
export const getOrderResult = async (
  { orderId }: OrderParams,
  headers: { Authorization: string }
): Promise<GetOrderResultResponse> => {
  const endpoint = ENDPOINTS.ORDER + "/" + orderId;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error("Failed to get order");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting order:", error);
    throw error;
  }
};
