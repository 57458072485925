import { FC, useState } from "react";
import { Select, MenuItem, SelectChangeEvent, SxProps } from "@mui/material";
import { Lock, UpgradeSharp } from "@mui/icons-material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { PATHS } from "../../routes/routes";
import { useTranslation } from "react-i18next";
import { customColors } from "../../theme";

interface BaseSelectProps {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  menuItems: {
    label: string;
    value: string;
    icon?: string;
    locked?: boolean;
  }[];
  disabled?: boolean;
  sx?: SxProps;
  menuSx?: SxProps;
}

export const BaseSelect: FC<BaseSelectProps> = ({
  value,
  onChange,
  menuItems,
  disabled = false,
  sx = {},
  menuSx = {},
}) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      disabled={disabled}
      sx={{
        color: "#fff",
        borderColor: "#fff",
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#ccc",
        },
        width: "100%",
        ".MuiSelect-select": {
          display: "flex",
          alignItems: "center",
          paddingTop: "6px",
          paddingBottom: "6px",
        },
        ".MuiSvgIcon-root": {
          color: "#fff",
        },
        ...sx,
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            bgcolor: "#333",
            color: "#fff",
            ...menuSx,
          },
        },
      }}
    >
      {menuItems.map(({ label, value, icon, locked }) => (
        <MenuItem
          key={value}
          value={locked ? "" : value}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "40px",
          }}
        >
          <ItemContent label={label} icon={icon} locked={locked} />
        </MenuItem>
      ))}
    </Select>
  );
};

const ItemContent = ({
  label,
  icon,
  locked,
}: {
  label: string;
  icon?: string;
  locked?: boolean;
}) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);

  return (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
      onMouseEnter={() => locked && setHovered(true)}
      onMouseLeave={() => locked && setHovered(false)}
    >
      {locked ? (
        <Link
          to={PATHS.pricing}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            textDecoration: "none",
            color: "#888",
            width: "100%",
          }}
        >
          {hovered ? (
            <>
              <UpgradeSharp
                fontSize="small"
                sx={{ color: customColors.gold }}
              />
              <span
                style={{
                  color: customColors.gold,
                }}
              >
                {t("TEXT.UPGRADE_TO_UNLOCK")}
              </span>
            </>
          ) : (
            <>
              <Lock fontSize="small" />
              <span>{label}</span>
            </>
          )}
        </Link>
      ) : (
        <>
          {icon && (
            <img
              src={icon}
              alt=""
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                objectFit: "contain",
              }}
            />
          )}
          <span>{label}</span>
        </>
      )}
    </span>
  );
};
